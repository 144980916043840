@charset "UTF-8";

@import "../../assets/scss/components/tokens/showcase3.style";

$component: componentClass("showcase3");
$cmp-tokens: ();

@if (variable-exists(zaux-showcase3-style-tokens)){
    $cmp-tokens : $zaux-showcase3-style-tokens;
}

.#{$component} {
    overflow:hidden;
    &__inner{
        padding-top: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(9), 'layout');
        padding-bottom: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(9), 'layout');
        .#{$component}{
            &__head{
                text-align:getCmpToken($cmp-tokens,'css-head-textalign', left, 'layout');
                margin-bottom:getCmpToken($cmp-tokens, 'spacer-head-mb', tokenSpacer(6), 'layout');
                padding-top:getCmpToken($cmp-tokens, 'spacer-head-pt', tokenSpacer(6), 'layout');
                .#{$component}{
                    &__title{
                        font-size: getCmpToken($cmp-tokens, 'fs-title', tokenTypoSizeFs(14b), 'layout');
                        line-height: getCmpToken($cmp-tokens, 'lh-title', tokenTypoSizeLh(14b), 'layout');
                        font-weight: getCmpToken($cmp-tokens, 'fw-title', $zaux-typo-fw-bold, 'layout');
                        margin-bottom:0px;
                    }
                }
            }
            &__slider{
                .#{$component}__slide{
                    height:auto !important;
                    > *{
                        height:100%;
                    }
                }
            }
        }
    }   

    //Variants
    &--lower-pb{
        .#{$component}{
            &__inner{
                padding-top: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(9), 'layout');
                padding-bottom: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(7), 'layout');
            }
        }
    }
    
    &.res{
        &--1{
            .#{$component-prefix}slider__instance{
                @include media-breakpoint-down("md"){
                    padding-bottom: getCmpToken($cmp-tokens, 'spacer-sliderinner-pb', tokenSpacer(8), 'layout-res1-md');
                }
            }
            .#{$component}{
                &__inner{
                    @include media-breakpoint-down("md"){
                        padding-top: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(6), 'layout-res1-md');
                        padding-bottom: getCmpToken($cmp-tokens, 'spacer-inner-pb', tokenSpacer(6), 'layout-res1-md');
                    }
                }
                &__pagination{
                    @include media-breakpoint-up("md"){
                        display:none;
                    }
                }
            }
            &.#{$component}--lower-pb{
                .#{$component}{
                    &__inner{
                        @include media-breakpoint-down("md"){
                            padding-top:getCmpToken($cmp-tokens, 'spacer-inner-pb', tokenSpacer(6), 'layout-res1-lower-pb-md');
                            padding-bottom: getCmpToken($cmp-tokens, 'spacer-inner-pb', tokenSpacer(6), 'layout-res1-lower-pb-md');
                        }
                    }
                }
            }
        }
    }

}
