@charset "UTF-8";

@import "../../assets/scss/components/tokens/displayblock1.style";

$component: componentClass("displayblock1");
$cmp-tokens: ();

@if (variable-exists(zaux-displayblock1-style-tokens)){
    $cmp-tokens : $zaux-displayblock1-style-tokens;
}

.#{$component} {
    $cmp-min-height : getCmpToken($cmp-tokens, 'css-cmp-minheight', 600px, 'layout');
    min-height:$cmp-min-height;
    overflow:hidden;
    position:relative;
    &__bg{
        position:absolute;
        right:0;
        height:100%;
        top:0;
        max-width: 755px;
        bottom:0;
        img{
            height:100%;
            width:auto;
        }
    }
    &__inner{
        min-height:$cmp-min-height;
        //padding: getCmpToken($cmp-tokens, 'spacer-inner-py', tokenSpacer(7), 'layout') 0;
        .#{$component}{
            &__title{
                @include tokenTypoStyle(getCmpToken($cmp-tokens, 'typo-title', 16, 'layout'));
                margin-bottom:getCmpToken($cmp-tokens, 'spacer-title-mb', tokenSpacer(5), 'layout');
            }
            &__subtitle{
                @include tokenTypoStyle(getCmpToken($cmp-tokens, 'typo-subtitle', 14, 'layout'));
                margin-bottom:getCmpToken($cmp-tokens, 'spacer-subtitle-mb', tokenSpacer(5), 'layout');
            }
            &__abstract{
                @include tokenTypoStyle(getCmpToken($cmp-tokens, 'typo-abstract', 10, 'layout'));
                margin-bottom:getCmpToken($cmp-tokens, 'spacer-abstract-mb', tokenSpacer(6), 'layout');
            }
            &__row{
                .#{$component}__col{
                    position:relative;
                    z-index:tokenZindex(content);
                }
            }
        }
    }

    &--flipped{
        .#{$component}__bg{
            left:0;
            height:100%;
            bottom:0;
            right:auto;
        }
    }

    &.res{
        &--1{
            @include media-breakpoint-down("md"){
                min-height:auto;
                padding: tokenSpacer(8) 0;
            }
            .#{$component}{
                &__bg{
                    @include media-breakpoint-down("lg"){
                        opacity:0.5;
                    }
                    @include media-breakpoint-down("sm"){
                        opacity:0.3;
                        //max-height:250px;
                    }
                }
                &__inner{
                    @include media-breakpoint-down("md"){
                        min-height:auto;
                    }
                }
                &__title{
                    @include media-breakpoint-down("md"){
                        font-size:getCmpToken($cmp-tokens, 'fs-title', tokenTypoSizeFs(15), 'layout-res1-md');
                        line-height:getCmpToken($cmp-tokens, 'lh-title', tokenTypoSizeLh(15), 'layout-res1-md');
                    }
                }
                &__bg{
                    @include media-breakpoint-down("sm"){
                        right:-20%;
                    }
                    @media(max-width: tokenBreakpoint("_high-xs")){
                        right:-40%;
                    }
                }
            }
        }
    }
}