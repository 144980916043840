@charset "UTF-8";

@import "../../assets/scss/components/tokens/filterlist1.style";

$component: componentClass("filterlist1");
$cmp-tokens: ();

@if (variable-exists(zaux-filterlist1-style-tokens)){
    $cmp-tokens : $zaux-filterlist1-style-tokens;
}

.#{$component} {
    padding: getCmpToken($cmp-tokens, 'spacer-cmp-py', tokenSpacer(6), 'layout');
    &__title{
        font-weight:getCmpToken($cmp-tokens, 'fw-title', $zaux-typo-fw-bold, 'layout');
    }
    &__list{
        padding: getCmpToken($cmp-tokens, 'spacer-list-py', tokenSpacer(4), 'layout') 0;
        padding-bottom:0px;
        *{
            list-style-type:none;
        }
        &-item{
            padding: getCmpToken($cmp-tokens, 'spacer-list-py', tokenSpacer(2), 'layout') 0;

            ul{
                margin-left:getCmpToken($cmp-tokens, 'spacer-sublist-ml', tokenSpacer(4), 'layout');
                li{
                    padding: getCmpToken($cmp-tokens, 'spacer-list-py', tokenSpacer(2), 'layout') 0;
                    &:last-child{
                        padding-bottom:0px;
                    }
                }
            }
        }
    }
}