@charset "UTF-8";

@import "../../assets/scss/components/tokens/footer.style";

$component: componentClass("footer");
$cmp-tokens: ();

@if ($zaux-footer-style-tokens){
    $cmp-tokens : $zaux-footer-style-tokens;
}

.#{$component} {

    max-width:1465px;
    margin-left:auto;
    margin-right:auto;
    border-top-width: getCmpToken($cmp-tokens, 'css-cmp-bordertopwidth', 1px, 'layout');
    position:relative;

    &:before{
        position:absolute;
        content:"";
        top:0;
        left:0;
        width:100%;
        height:32px;
        background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="34.3" height="18.256" viewBox="0 0 34.3 18.256"> <g id="Raggruppa_341" data-name="Raggruppa 341" transform="translate(2524.5 -6752.025)"> <path id="Tracciato_3192" data-name="Tracciato 3192" d="M16.221,16.251C7.549,16.251.457,9.082.163.16H32.279c-.294,8.922-7.386,16.091-16.058,16.091" transform="translate(-2523.571 6752.947)" fill="%23e4d1b6"/> <path id="Tracciato_3192_-_Contorno" data-name="Tracciato 3192 - Contorno" d="M16.221,16.751A16.18,16.18,0,0,1,4.7,11.9,17.364,17.364,0,0,1-.337.176L-.354-.34H32.8l-.017.516A17.364,17.364,0,0,1,27.746,11.9,16.18,16.18,0,0,1,16.221,16.751ZM.687.66A15.761,15.761,0,0,0,16.221,15.751,15.761,15.761,0,0,0,31.755.66Z" transform="translate(-2523.571 6752.947)" fill="%234f1f13"/> <path id="Tracciato_3193" data-name="Tracciato 3193" d="M32.1,1.162C31.524,9.5,24.813,16.094,16.65,16.094S1.776,9.5,1.2,1.162ZM33.3,0H0C0,9.529,7.455,17.256,16.65,17.256S33.3,9.529,33.3,0" transform="translate(-2524 6752.525)" fill="%23e4d1b6"/> <path id="Tracciato_3193_-_Contorno" data-name="Tracciato 3193 - Contorno" d="M16.65,17.756a16.539,16.539,0,0,1-6.681-1.4,17.075,17.075,0,0,1-5.451-3.809A17.962,17.962,0,0,1-.5,0V-.5H33.8V0a17.962,17.962,0,0,1-5.017,12.549,17.075,17.075,0,0,1-5.451,3.809A16.539,16.539,0,0,1,16.65,17.756ZM.507.5c.256,9.009,7.4,16.256,16.143,16.256S32.537,9.509,32.793.5ZM16.65,16.594A15.614,15.614,0,0,1,5.731,12.112,16.789,16.789,0,0,1,.7,1.2L.666.662H32.634L32.6,1.2a16.789,16.789,0,0,1-5.028,10.916A15.614,15.614,0,0,1,16.65,16.594ZM1.747,1.662a15.272,15.272,0,0,0,14.9,13.932,15.272,15.272,0,0,0,14.9-13.932Z" transform="translate(-2524 6752.525)" fill="%234f1f13"/> </g></svg>');
        background-repeat:repeat-x;
        backround-position:center;
    }

    &__logo{
        max-width:100%;
    }
    &__inner{
        padding-top: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(8), 'layout');
        padding-bottom: getCmpToken($cmp-tokens, 'spacer-inner-pb', tokenSpacer(7), 'layout');
    }
    &__links{
        list-style-type:none;
        li{
            font-size: getCmpToken($cmp-tokens, 'fs-footer-links-item', tokenTypoSizeFs(6), 'layout');
            margin-bottom:getCmpToken($cmp-tokens, 'spacer-footer-links-item-mb', tokenSpacer(3), 'layout');
        }
    }
    &__slogan-row{
        text-align:right;
        font-size: tokenTypoSizeFs(9);
        padding-top: getCmpToken($cmp-tokens, 'spacer-slogan-row-pt', tokenSpacer(5), 'layout');
    }
    
    &__row{
        &--2{
            padding-top:getCmpToken($cmp-tokens, 'spacer-row2-pt', tokenSpacer(3), 'layout');
            font-size: getCmpToken($cmp-tokens, 'fs-row2', tokenTypoSizeFs(6), 'layout' );
            line-height: getCmpToken($cmp-tokens, 'lh-row2', tokenTypoSizeLh(6), 'layout');
            font-family: $zaux-font2;
        }
    }
    
    &__social{
        list-style-type:none;
        margin-left:-#{getCmpToken($cmp-tokens, 'spacer-social-item-ml', tokenSpacer(3), 'layout')};
        &-item{
            display:inline-block;
            border-radius:tokenRadius("full");
            height:32px;
            width:32px;
            text-align:center;
            margin-left:getCmpToken($cmp-tokens, 'spacer-social-item-ml', tokenSpacer(3), 'layout');
            a{
                display:flex;
                justify-content:center;
                width:100%;
                height:100%;
                flex-direction:column;
            }
        }
        //.#{$component-prefix}icon{
           //font-size:tokenIconSize(3);
        //}
    }

    &__credits{
        padding: getCmpToken($cmp-tokens, 'spacer-credits-py', tokenSpacer(4), 'layout') 0px;
        font-size: getCmpToken($cmp-tokens, 'fs-credits', tokenTypoSizeFs(6), 'layout');
        overflow: hidden;
        &-icon{
            position:absolute;
            top:-16px;
            right:tokenSpacer(4);
            .#{componentClass("icon")}{
                font-size:78px;
            }
        }
    }

    &.res{
        &--1{
            .#{$component}__inner{
                @include media-breakpoint-down("md"){
                    padding-top: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(5), 'layout-res1-md');
                    padding-bottom: getCmpToken($cmp-tokens, 'spacer-inner-pb', tokenSpacer(5), 'layout-res1-md');
                }
            }
            .#{$component}__logo{
                @include media-breakpoint-down("md"){
                    max-width:200px;
                    height:auto;
                }
            }
        }
    }
}
