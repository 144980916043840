@charset "UTF-8";


$component: componentClass("decobar1");
////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    &__deco{
        &--1{
            background-color:tokenColor("set1","color6");
            border-top:1px solid tokenColor("set1","color3");
            border-bottom:1px solid tokenColor("set1","color3");
            height:10px;
        }
    }
}