@charset "UTF-8";

@import "../../assets/scss/components/tokens/showcase1.style";

$component: componentClass("productshowcase");
$cmp-tokens: ();

@if (variable-exists(zaux-productshowcase-style-tokens)){
    $cmp-tokens : $zaux-productshowcase-style-tokens;
}

.#{$component} {
    &__pre-title{
        text-transform:uppercase;
        letter-spacing:getCmpToken($cmp-tokens, 'ls-pretitle', tokenLetterSpacing(1), 'layout');
        margin-bottom:getCmpToken($cmp-tokens, 'spacer-pretitle-mb', tokenSpacer(4), 'layout');
        @include tokenTypoStyle(9b);
    }
    &__sidepics{
        .#{$component}__sidepics-main{
            img{
                max-width:100%;
            }
        }
    }
    &__title{
        @include tokenTypoStyle(15b);
        margin-bottom:getCmpToken($cmp-tokens, 'spacer-title-mb', tokenSpacer(1), 'layout');
        font-weight:getCmpToken($cmp-tokens, 'fw-title', $zaux-typo-fw-bold, 'layout');
    }
    &__subtitle{
        @include tokenTypoStyle(13);
        margin-bottom:getCmpToken($cmp-tokens, 'spacer-subtitle-mb', tokenSpacer(3), 'layout');
    }
    &__productcode{
        display:inline-block;
        padding: getCmpToken($cmp-tokens, 'spacer-productcode-pxy', tokenSpacer(2), 'layout');
        font-size: getCmpToken($cmp-tokens, 'fs-productcode', tokenTypoSizeFs(6), 'layout' );
    }
    &__price-label{
        font-size:getCmpToken($cmp-tokens, 'fs-pricelabel', tokenTypoSizeFs(15), 'layout');
        font-weight:getCmpToken($cmp-tokens, 'fw-pricelabel', $zaux-typo-fw-medium, 'layout');
        &--strike{
            text-decoration: line-through;
            margin-right:getCmpToken($cmp-tokens, 'spacer-pricelabelstrike-mr', tokenSpacer(6), 'layout');
        }
    }
    &__row{
        &--1{
            text-align:getCmpToken($cmp-tokens, 'css-row1-txtalign', right, 'layout');
            padding: getCmpToken($cmp-tokens, 'spacer-row1-py', tokenSpacer(6), 'layout') 0;
            margin-bottom:getCmpToken($cmp-tokens, 'spacer-row1-mb', tokenSpacer(4), 'layout');
        }
        &--2{
            padding-bottom: getCmpToken($cmp-tokens, 'spacer-row2-pb', tokenSpacer(10), 'layout');

        }
    }
    &__product-info{
        list-style-type:none;
        &-item{
            border-bottom-width: getCmpToken($cmp-tokens, 'css-product-info-item-borderw', 1px, 'layout');
            padding: getCmpToken($cmp-tokens, 'spacer-product-info-item-py', tokenSpacer(4), 'layout') 0;
            font-size: getCmpToken($cmp-tokens, 'fs-product-info-item', tokenTypoSizeFs(9b), 'layout');
        }
    }
    &__badge-list{
        list-style-type:none;
    }
    &__badge{
        display:inline-block;
        height:getCmpToken($cmp-tokens, 'css-badge-height', 82px, 'layout' );
        width:getCmpToken($cmp-tokens, 'css-badge-width', 82px, 'layout' );
        padding-left:getCmpToken($cmp-tokens, 'spacer-badge-pl', tokenSpacer(2), 'layout');
        padding-right:getCmpToken($cmp-tokens, 'spacer-badge-pr', tokenSpacer(2), 'layout');
        text-align:center;
        display:flex;
        justify-content:center;
        align-items:center;
        font-size: getCmpToken($cmp-tokens, 'fs-badge', tokenTypoSizeFs(14), 'layout');
    }
    &.res{
        &--1{
            .#{$component}{
                &__price-label{
                    &--strike{
                        @include media-breakpoint-down("sm"){
                            display:block;
                        }
                    }
                }
                &__row{
                    &--2{
                        @include media-breakpoint-down("md"){
                            padding-bottom: getCmpToken($cmp-tokens, 'spacer-row2-pb', tokenSpacer(3), 'layout-res1-md');
                        }
                    }
                }
                &__sidepics{
                    .#{$component}__sidepics-main{
                        text-align:center;
                        img{
                            @include media-breakpoint-down("md"){
                                max-width: getCmpToken($cmp-tokens, 'css-img-maxwidth', 300px, 'layout-res1-md');
                            }
                        }
                    }
                }
            }
            .#{$component}__badge-list{
                @include media-breakpoint-down("md"){
                    position:absolute;
                    right:getCmpToken($cmp-tokens, 'css-badgelist-right', tokenSpacer(2), 'layout-res1-md');
                    top: getCmpToken($cmp-tokens, 'css-badgelist-top', -20px, 'layout-res1-md');
                }
            }
        }
    }
}
