@charset "UTF-8";

@import "../../assets/scss/components/tokens/showcase3.style";

$component: componentClass("showcase3");
$cmp-tokens: ();

@if ($zaux-showcase3-style-tokens){
    $cmp-tokens : $zaux-showcase3-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{

    background-color: getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor('set1','color2'), 'theme-light1');
    color:tokenColor("set1","color3");

}