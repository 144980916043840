@charset "UTF-8";

@import "../../assets/scss/components/tokens/archivegrid.style";

$component: componentClass("archivegrid");
$cmp-tokens: ();

@if (variable-exists(zaux-archivegrid-style-tokens)){
    $cmp-tokens : $zaux-archivegrid-style-tokens;
}

.#{$component} {
    padding: getCmpToken($cmp-tokens, 'spacer-cmp-py', tokenSpacer(10), 'layout') 0;
    &.res{
        &--1{
            @include media-breakpoint-down("md"){
                padding: getCmpToken($cmp-tokens, 'spacer-cmp-py', tokenSpacer(6), 'layout-res1-md') 0; 
            }
        }
    }
}