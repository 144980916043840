@charset "UTF-8";

@import "../../assets/scss/components/base";
@import "../../assets/scss/components/tokens/heroslider3.style";

$component: componentClass("heroslider3");
$cmp-tokens: ();

@if ($zaux-heroslider3-style-tokens){
    $cmp-tokens : $zaux-heroslider3-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{

    &__overlay{
        background-color:rgba(0,0,0,0.3);
    }
    &__pre-title{
        color:tokenColor("set1","white1");
    }
    &__title{
        color:tokenColor("set1","white1");
    }
    &__subtitle{
        color:tokenColor("set1","white1");
    }
    &__badge{
        background-color:getCmpToken($cmp-tokens, 'color-badge-bg', tokenColor("set1", "color2"), 'theme-light1');
        border-radius:tokenRadius("full");
        &--style2{
            background-color:getCmpToken($cmp-tokens, 'color-badge2-bg', tokenColor("set1", "white1"), 'theme-light1');
        }
    }
    &__slide-content{
        background-color:tokenColor("set1","black1");
    }

}