@charset "UTF-8";

@import "../../assets/scss/components/tokens/cardproduct1.style";

$component: componentClass("cardproduct1");
$cmp-tokens: ();

@if ($zaux-cardproduct1-style-tokens){
    $cmp-tokens : $zaux-cardproduct1-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color: tokenColor("set1","color6");
    color: getCmpToken($cmp-tokens, 'color-cmp-textcolor', tokenColor("set1", "color3"), 'theme-light1');
    border-radius:tokenRadius(2);

    &__icon{
        color: getCmpToken($cmp-tokens, 'color-icon', tokenColor("set1", "color3"), "theme-light1");
    }
    &__pre-title{
        font-family: $zaux-font2; 
        color: getCmpToken($cmp-tokens, 'color-pretitle', tokenColor("set1", "color3"), "theme-light1");
    }
    &__abstract{
        color:tokenColor("set1","color3");
    }
    &__title{
        color: getCmpToken($cmp-tokens, 'color-title', tokenColor("set1", "color3"), 'theme-light1');
    }
    &__marked-txt{
        color: getCmpToken($cmp-tokens, 'color-markedtxt', tokenColor("set1", "color3"), 'theme-light1');
    }
}