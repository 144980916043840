@charset "UTF-8";

@import "../../assets/scss/components/tokens/showcase1.style";

$component: componentClass("breadcrumbs");
$cmp-tokens: ();

@if (variable-exists(zaux-breadcrumbs-style-tokens)){
    $cmp-tokens : $zaux-breadcrumbs-style-tokens;
}

.#{$component} {
    &--type1{
        font-size:getCmpToken($cmp-tokens, 'fs-crumbs', tokenTypoSizeFs(3) + 1, 'layout');
    }
}
