@charset "UTF-8";

@import "../../assets/scss/components/tokens/newsletterbox1.style";

$component: componentClass("newsletterbox1");
$cmp-tokens: ();

@if (variable-exists(zaux-newsletterbox1-style-tokens)){
    $cmp-tokens : $zaux-newsletterbox1-style-tokens;
}

.#{$component} {
    &__inner{
        min-height: getCmpToken($cmp-tokens, 'css-inner-minheight', 500px, 'layout');
    }
    &__row{
        &--1{
            min-height:inherit;
            .#{$component}__col{
                &--1{
                    position:relative;
                    .#{$component}__visual{
                        position:absolute;
                        left:-160px;
                        top:-50px;
                    }
                }
                &--2{
                    padding: getCmpToken($cmp-tokens, 'spacer-col2-py', tokenSpacer(8), 'layout') 0;
                    position:relative;
                    z-index:tokenZindex(2);
                }
            }
        }
    }
    &__title{
        font-size:getCmpToken($cmp-tokens, 'fs-title', tokenTypoSizeFs(15b), 'layout');
        line-height: getCmpToken($cmp-tokens, 'lh-title', tokenTypoSizeLh(15b), 'layout');
        margin-bottom: getCmpToken($cmp-tokens, 'spacer-title-mb', tokenSpacer(4), 'layout');
        font-weight: getCmpToken($cmp-tokens, 'fw-title', $zaux-typo-fw-bold, 'layout');
    }
    &__subtitle{
        font-size:getCmpToken($cmp-tokens, 'fs-subtitle', tokenTypoSizeFs(13), 'layout');
        line-height: getCmpToken($cmp-tokens, 'lh-subtitle', tokenTypoSizeFs(13), 'layout');
        margin-bottom: getCmpToken($cmp-tokens, 'spacer-subtitle-mb', tokenSpacer(6), 'layout');
        font-family: $zaux-font2;
    }
    &__toslabel{
        padding-top: getCmpToken($cmp-tokens, 'spacer-toslabel-pt', tokenSpacer(4), 'layout');
        font-size: getCmpToken($cmp-tokens, 'fs-toslabel', tokenTypoSizeFs(9b), 'layout');
        line-height: getCmpToken($cmp-tokens, 'lh-toslabel', tokenTypoSizeLh(9b), 'layout');
        font-family: $zaux-font2;
    }
    .wpcf7-spinner{
        top:12px;
    }
    .wpcf7-not-valid-tip{
        display:none !important;
    }
    &.res{
        &--1{
            .#{$component}{
                &__inner{
                    @include media-breakpoint-down("md"){
                        min-height:auto;
                        padding: getCmpToken($cmp-tokens, 'spacer-inner-py', tokenSpacer(8), 'layout-res1-md') 0;
                    }
                }
                &__col--2{
                    @include media-breakpoint-down("md"){
                        padding: getCmpToken($cmp-tokens, 'spacer-col2-py', tokenSpacer(0), 'layout-res1-md') 0;
                    }
                }
            }
        }
    }
}