@charset "UTF-8";

@import "../../assets/scss/components/tokens/textblock.style";

$component: componentClass("textblock");
$cmp-tokens: ();

@if (variable-exists(zaux-textblock-style-tokens)){
    $cmp-tokens : $zaux-textblock-style-tokens;
}

.#{$component} {

    img{
        max-width:100%;
        height:auto;
    }

    &__title{
        @include tokenTypoStyle(15b);
        font-weight: getCmpToken($cmp-tokens, "fw-title", $zaux-typo-fw-bold, 'layout');
    }

    &__inner{
        padding: getCmpToken($cmp-tokens, "spacer-inner-py", tokenSpacer(10), 'layout') 0;
    }

    &__head{
        padding-bottom: getCmpToken($cmp-tokens, "spacer-head-pb", tokenSpacer(3), 'layout');
    }

    &__body{
        padding: getCmpToken($cmp-tokens, "spacer-body-py", tokenSpacer(2), 'layout') 0;
    }

    &[class*=--reducepad]{
        .#{$component}__inner{
            padding: getCmpToken($cmp-tokens, "spacer-inner-py", tokenSpacer(5), 'layout') 0;
        }
    }

    &[class*=--nopad]{
        .#{$component}__inner{
            padding:0 !important;
        }
    }

}