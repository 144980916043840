@charset "UTF-8";

@import "../../assets/scss/components/tokens/showcase1.style";

$component: componentClass("infocard");
$cmp-tokens: ();

@if (variable-exists(zaux-infocard-style-tokens)){
    $cmp-tokens : $zaux-infocard-style-tokens;
}

.#{$component} {
    &__inner{
        padding: getCmpToken($cmp-tokens, 'spacer-cmp-pxy', tokenSpacer(6), 'layout');
        min-height:getCmpToken($cmp-tokens, 'css-inner-minheight', 270px, 'layout');
        display:flex;
        flex-direction:column;
        justify-content:space-between;
    }
    &__title{
        margin-bottom:getCmpToken($cmp-tokens,'spacer-title-mb', tokenSpacer(3), 'layout');
        font-size: getCmpToken($cmp-tokens, 'fs-title', tokenTypoSizeFs(14b), 'layout');
    }
}
