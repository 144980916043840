@charset "UTF-8";

@import "../../assets/scss/components/tokens/showcase1.style";

$component: componentClass("showcase1");
$cmp-tokens: ();

@if ($zaux-showcase1-style-tokens){
    $cmp-tokens : $zaux-showcase1-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{

    background-color:getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor('set1', 'color2'), 'theme-light1' );
    color: getCmpToken($cmp-tokens, 'color-cmp-text', tokenColor('set1', 'black1'), 'theme-light1');

    &__title{
        color: getCmpToken($cmp-tokens, 'color-title', tokenColor('set1', 'color2'), 'theme-light1');
    }

    &__subtitle{
        font-family: $zaux-font2;
    }

    &--theme2{
        background-color:tokenColor("set1","color7");
    }

}