@charset "UTF-8";

@import "../../assets/scss/components/tokens/offcanvasnav.style";

$component: componentClass("offcanvasnav");
$cmp-tokens: ();

@if (variable-exists(zaux-offcanvasnav-style-tokens)){
    $cmp-tokens : $zaux-offcanvasnav-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color:getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor(set1,color4), 'theme-light1' );
    box-shadow: getCmpToken($cmp-tokens, "dropshadow-header", tokenShadow('sm') rgba(tokenColor('set1', 'black1'), 0.4), "theme-light1");
    color: getCmpToken($cmp-tokens, "color-cmp-txtcolor", tokenColor(set1,white1),'theme-light1');

    &__menu{
        ul{
            li{
                background-color:getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor(set1,color4), 'theme-light1' );
                transition:background-color $zaux-transition-1;
                &:hover{
                    background-color:lighten( getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor(set1,color4), 'theme-light1' ), 10% );
                }
                &:active{
                    background-color:darken( getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor(set1,color4), 'theme-light1' ), 10% );
                }
                a{
                    color:inherit;
                }
            }
        }
    }
    &__submenu{
        background-color:darken(tokenColor("set1","color4"),15%);
        li{
            background-color:darken(tokenColor("set1","color4"),15%) !important;
        }
    }
    
}