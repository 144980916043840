@charset "UTF-8";

@import "../../assets/scss/components/tokens/displayblock1.style";

$component: componentClass("displayblock2");
$cmp-tokens: ();

@if (variable-exists(zaux-displayblock2-style-tokens)){
    $cmp-tokens : $zaux-displayblock2-style-tokens;
}

.#{$component} {
    &__bg{
        img{
            width:100%;
            @include media-breakpoint-down("sm"){
                max-height:200px;
                width:auto;
            }
        }
    }
}