@charset "UTF-8";

@import "../../assets/scss/components/base";
@import "../../assets/scss/components/tokens/heroslider2.style";

$component: componentClass("heroslider2");
$cmp-tokens: ();

@if ($zaux-heroslider2-style-tokens){
    $cmp-tokens : $zaux-heroslider2-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{

    &__pre-title{
        border-radius:tokenRadius(1);
        background-color: getCmpToken($cmp-tokens, 'color-pretitle-bg', tokenColor('set1', 'color2'), 'theme-light1');
    }
    &__title{
        color:getCmpToken($cmp-tokens, 'color-title', tokenColor('set1','color1'), 'theme-light1');
    }
    &__subtitle{
        color:getCmpToken($cmp-tokens, 'color-subtitle', tokenColor('set1','color4'), 'theme-light1');
    }
    &__badge{
        background-color:getCmpToken($cmp-tokens, 'color-badge-bg', tokenColor("set1", "color2"), 'theme-light1');
        border-radius:tokenRadius("full");
        &--style2{
            background-color:getCmpToken($cmp-tokens, 'color-badge2-bg', tokenColor("set1", "white1"), 'theme-light1');
        }
    }
    &__slide-content{
        background-color: getCmpToken($cmp-tokens, 'color-slide-bg', tokenColor('set1', 'color1'), 'theme-light1');
    }

}