@charset "UTF-8";

@import "../../assets/scss/components/tokens/cart.style";

$component: componentClass("cart");
$cmp-tokens: ();

@if (variable-exists(zaux-cart-style-tokens)){
    $cmp-tokens : $zaux-cart-style-tokens;
}

.#{$component} {
    
    &__row{
        &--1{
            text-align:getCmpToken($cmp-tokens, 'css-row1-txtalign', right, 'layout');
            padding: tokenSpacer(6) 0;
            margin-bottom: tokenSpacer(8);
        }
    }

}