@charset "UTF-8";

@import "../../assets/scss/components/tokens/displayblock1.style";

$component: componentClass("displayblock1");
$cmp-tokens: ();

@if (variable-exists(zaux-displayblock1-style-tokens)){
    $cmp-tokens : $zaux-displayblock1-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{

    background-color:tokenColor("set1","color8");
    color:tokenColor("set1","color3");

    &__title{
        font-weight: getCmpToken($cmp-tokens, 'fw-title', $zaux-typo-fw-semibold, 'theme-light1');
    }

    &__subtitle{
        color: getCmpToken($cmp-tokens, 'color-subtitle', tokenColor('set1','color2'), 'theme-light1');
    }

    &--theme2{
        background-color:tokenColor("set1","color2");
        color:tokenColor("set1","color3");
    }

}