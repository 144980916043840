$component: componentClass("pagination");
$cmp-tokens: ();

.#{$component}{
    border-top-width:1px;
    .wp-pagenavi{
       .current{
            padding:tokenSpacer(1) tokenSpacer(2);
       }
    }
}