@charset "UTF-8";

@import "../../assets/scss/components/tokens/decolistbox1.style";

$component: componentClass("decolistbox1");
$cmp-tokens: ();

@if (variable-exists(zaux-decolistbox1-style-tokens)){
    $cmp-tokens : $zaux-decolistbox1-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{

    background-color:getCmpToken($cmp-tokens, "color-cmp-bg", tokenColor("set1","color3"), "theme-light1");
    color: getCmpToken($cmp-tokens, "color-cmp-txtcolor", tokenColor("set1","color4"), "theme-light1");
    &__deco{
        &--top{
            .#{$component}__line{
                border-top:1px solid getCmpToken($cmp-tokens, "color-line", tokenColor('set1','color4'), 'theme-light1');
            }
            .#{$component}__icon{
                background-color: getCmpToken($cmp-tokens, "color-cmp-bg", tokenColor("set1","color3"), "theme-light1");
                color:getCmpToken($cmp-tokens, 'color-decoicon', tokenColor('set1','color1'), 'theme-light1');
            }
        }
    }
    &__title{
        color:getCmpToken($cmp-tokens, 'color-title', tokenColor('set1', 'color2'), 'theme-light1');
    }
    &__deco-rect{
        background-color:getCmpToken($cmp-tokens, "color-decorect-bg", tokenColor("set1","white1"), 'theme-light1');
        border:1px solid getCmpToken($cmp-tokens, "color-decorect-bordercolor", tokenColor("set1", "color4"), 'theme-light1');
        border-radius:tokenRadius(getCmpToken($cmp-tokens, "radius-decorect", 1, "theme-light1"));
    }
}