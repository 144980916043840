$zaux-token-typo:(
  base-font-size: 16px,
  breakpoints: (sm, md, lg, xl),
  sizes: (
    3: (
      fs: 12px,
      lh: 16px
    ),
    6: (
      fs: 14px,
      lh: 22px
    ),
    9: (
      fs: 16px,
      lh: 26px
    ),
    10: (
      fs: 18px,
      lh: 24px
    ),
    12: (
      fs: 20px,
      lh: 17px
    ),
    13: (
      fs: 22px,
      lh: 28px
    ),
    14: (
      fs: 24px,
      lh: 26px
    ),
    15: (
      fs: 40px,
      lh: 43px
    ),
    16: (
      fs: 62px,
      lh: 60px
    ),
    17: (
      fs: 82px,
      lh: 82px
    ),
    20: (
      fs: 110px,
      lh: 88px
    ),
    9b: (
      fs: 16px,
      lh: 20px
    ),
    14b: (
      fs: 32px,
      lh: 43px
    ),
    15b: (
      fs: 46px,
      lh: 50px
    )
  )
);
