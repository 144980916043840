$zaux-token-colors:(
  default: (
    white: #FFFFFF,
    black: #333333,
    red: #d9534f,
    green: #5cb85c,
    blue: #337ab7,
    magenta: #FF00FF,
    orange: #f0ad4e,
    warning: #f0ad4e,
    error: #d9534f
  ),
  app: (
    theme: #475461
  ),
  set1: (
    black1: #000000,
    white1: #FFFFFF,
    color1: #C8102E,
    color2: #FFF7EA,
    color3: #4F1F13,
    color4: #4C4D4C,
    color5: #141414,
    color6: #E2CDB0,
    color7: #F4EADB,
    color8: #F9F5EF
  )
);
