@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("form");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

@mixin form-field-theme-light1{
    border-style:none;
    appearance: none;

    &:not([type=submit]){
        background-color:transparent;
    }

    //// FIELD TYPES ////

    //Default and type 1
    &:is(input, select, textarea), &:not([class*=--type],[class*=btn]), &--type1, &--type1 input, &--type1 select, &--type1 textarea{

        ///// GENERIC INPUT /////
        transition: background-color $zaux-transition-0, border-color $zaux-transition-0, box-shadow $zaux-transition-0;
        border:none;
        appearance: none;
        border-bottom:1px solid tokenColor('set1', 'color4');
        outline:none;
        color: getCmpToken($cmp-tokens, 'color-inputtext', tokenColor(set1,black1), 'theme-light1');

        &:is(div){
            border-bottom:none;
        }
        
        &:focus{
            border-bottom:1px solid getCmpToken( $cmp-tokens, "color-fieldtype1_focus-borderbottom", tokenColor("set1", "color1"), 'theme-light1');
        }
        &::placeholder{
            color:getCmpToken($cmp-tokens, 'color-placeholder', tokenColor(set1,color-4), 'theme-light1');
        }
        &:after{
            transition: opacity $zaux-transition-0;
        }

        ///// SELECT /////

        &:is(select){
            $select-arrow-color: getCmpToken($cmp-tokens, "color-selectarrow", tokenColor("set1","black1"), "theme-light1" );
            $select-arrow-color: str-replace(#{$select-arrow-color}, "#", "%23");
            background-position:right center;
            background-repeat:no-repeat;
            background-size:16px 12px;
            border-radius:0;
            background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='#{$select-arrow-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        }

        ///// RADIOS /////

        &:is(input[type=radio]){
            border-style:solid;
            border-color: tokenColor('set1', 'color4');
            border-radius:tokenRadius("full");
            &:after{
                background-color:getCmpToken($cmp-tokens, 'color-radiodot', tokenColor('set1','color1'), 'theme-light1');
                border-radius:tokenRadius("full");
            }
            &:focus{
                border-color: getCmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', 'color1'), 'theme-light1' );
                box-shadow: 0px 0px 0px 4px rgba(getCmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', 'color1'), 'theme-light1' ), 0.2);
            }
            &:checked{
                border-color: getCmpToken($cmp-tokens, 'color-radioborder_checked', tokenColor('set1', 'color1'), 'theme-light1' );
            }
        }

        ///// CHECKBOXES /////

        &:is(input[type=checkbox]){
            border-style:solid;
            border-color: getCmpToken($cmp-tokens, 'color-checkboxborder', tokenColor('set1', 'black1'), 'theme-light1' );
            border-radius:tokenRadius(1);
            &:focus{
                border-color: getCmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', 'color1'), 'theme-light1' );
                box-shadow: 0px 0px 0px 3px rgba(getCmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', 'color1'), 'theme-light1' ), 0.2);
            }
            &:checked{
                background-color:getCmpToken($cmp-tokens, 'color-checkboxbg_focus', tokenColor('set1','color1'), 'theme-light1');
                border-color: getCmpToken($cmp-tokens, 'color-checkboxborder_focus', tokenColor('set1', 'color1'), 'theme-light1' );
                $checkbox-checkmark-color: getCmpToken($cmp-tokens, 'color-checkmark', tokenColor('set1','white1'), 'theme-light1' );
                $checkbox-checkmark-color: str-replace(#{$checkbox-checkmark-color}, "#", "%23");
                background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='#{$checkbox-checkmark-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
            }
        }

        &:is(input[type=file])::file-selector-button{
            appearance:none;
            font-size:inherit;
            @extend .c-btn, .c-btn--primary, .c-btn--size-s;
        }

    }

    //Type 2
    &--type2, &--type2 input, &--type2 select, &--type2 textarea{
        border-radius:tokenRadius("pill");
        border:none;
    }

    //Type 3
    &--type3, &--type3 input, &--type3 select, &--type3 textarea{
        border:none !important;
        &:not([type=submit]){
            background-color:transparent;
        }
    }

    /// LABELS ///
    
    &-label{
        color:getCmpToken($cmp-tokens, 'color-fieldlabel', tokenColor(set1,black1), 'theme-light1');
    }

    /// ICONS ///
    /// 
    &-icon{
        color:getCmpToken($cmp-tokens, 'color-fieldicondf', tokenColor("set1","black1"), 'theme-light1');
    }
}

.zaux-theme--light1{
    input,textarea,select{
        @include form-field-theme-light1;
    }
    input[type=submit]{
        @include button-primary-light1;
        color:inherit;
        font-size:inherit;
    }
}

.zaux-theme--light1 .#{$component}{

    &__field,input,textarea,select{
        @include form-field-theme-light1;
    }

    &__field-wrap{
        &--style2{
            border-radius:tokenRadius("pill");
            background-color:getCmpToken($cmp-tokens, 'color-fieldcontstyle1-bg', tokenColor("set1","white1"), 'theme-light1');
            border:getCmpToken($cmp-tokens, 'spacer-fieldwrapstyle2-borderwidth', tokenSpacer(1), 'theme-light1') solid getCmpToken($cmp-tokens, 'color-fieldwrapstyle2-border', tokenColor("set1",'white1'), 'theme-light1');
            .#{$component}__field, input, select, textarea{
                border-radius:tokenRadius("pill");
            }
        }
    }

}