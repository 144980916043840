@charset "UTF-8";

@import "../../assets/scss/components/tokens/productshowcase.style";

$component: componentClass("productshowcase");
$cmp-tokens: ();

@if (variable-exists(zaux-productshowcase-style-tokens)){
    $cmp-tokens : $zaux-productshowcase-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color: getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor('set1','color2'), 'theme-light1');   
    color: getCmpToken($cmp-tokens, 'color-cmp-text', tokenColor('set1', 'color3') , 'theme-light1');
    &__title{
        color:tokenColor('set1', 'color3');
    }
    &__productcode{
        background-color:getCmpToken($cmp-tokens,'color-productcode-bg', tokenColor("set1", 'white1'), 'theme-light1');
    }
    &__product-info{
        &-item{
            border-color: getCmpToken($cmp-tokens,'color-product-info-item-borderb', tokenColor('set1', 'color5'), 'theme-light1');
            border-bottom-style: getCmpToken($cmp-tokens, 'css-product-info-item-borderstyle', solid, 'theme-light1');
            font-family: $zaux-font2;
        }
    }
    &__price-label{
        &--strike{
            color:getCmpToken($cmp-tokens, 'color-pricelabel', tokenColor('set1','color5'), 'theme-light1');
        }
        font-weight:$zaux-typo-fw-bold;
        color:tokenColor("set1","color1");
    }
    &__badge{
        background-color:getCmpToken($cmp-tokens, 'color-badge-bg', tokenColor("set1", "color2"), 'theme-light1');
        border-radius:tokenRadius("full");
        color: getCmpToken($cmp-tokens, 'color-badge-text', tokenColor("set1", "white1"), 'theme-light1');
    }
}