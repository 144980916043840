@charset "UTF-8";

@import "../../assets/scss/components/tokens/showcase2.style";

$component: componentClass("showcase2");
$cmp-tokens: ();

@if (variable-exists(zaux-showcase2-style-tokens)){
    $cmp-tokens : $zaux-showcase2-style-tokens;
}

.#{$component} {
    overflow:hidden;
    &__inner{
        padding-top: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(9), 'layout');
        padding-bottom: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(9), 'layout');
        .#{$component}{
            &__head{
                text-align:center;
                margin-bottom:getCmpToken($cmp-tokens, 'spacer-head-mb', tokenSpacer(6), 'layout');
                padding-top:getCmpToken($cmp-tokens, 'spacer-head-pt', tokenSpacer(6), 'layout');
                .#{$component}{
                    &__title{
                        margin-bottom:0px;
                    }
                }
            }
        }
    }   

    &__slider{
        position:relative;
        .#{$component}__arrow{
            position:absolute;
            @include media-breakpoint-down("md"){
                display:none;
            }
            &--next{
                top:50%;
                transform:translateY(-50%);
                right:-#{getCmpToken($cmp-tokens, 'spacer-sliderarrownext-right', tokenSpacer(10), 'layout')};
                z-index:tokenZindex('content');
            }
            &--prev{
                top:50%;
                transform:translateY(-50%);
                left:-#{getCmpToken($cmp-tokens, 'spacer-sliderarrownext-left', tokenSpacer(10), 'layout')};
                z-index:tokenZindex('content');
            }
        }
    }

    &--reducepadtop{
        .#{$component}__inner{
            padding-top:tokenSpacer(6);
        }
    }
    
    &.res{
        &--1{
            .#{$component-prefix}slider__instance{
                @include media-breakpoint-down("md"){
                    padding-bottom: getCmpToken($cmp-tokens, 'spacer-sliderinner-pb', tokenSpacer(8), 'layout-res1-md');
                }
            }
            .#{$component}{
                &__head{
                    @include media-breakpoint-down("md"){
                        padding-top:0;
                    }
                }
                &__inner{
                    @include media-breakpoint-down("md"){
                        padding-top: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(6), 'layout-res1-md');
                        padding-bottom: getCmpToken($cmp-tokens, 'spacer-inner-pb', tokenSpacer(6), 'layout-res1-md');
                        &[class*=--reducepadtop]{
                            padding-top:0;
                        }
                    }
                }
                &__pagination{
                    @include media-breakpoint-up("md"){
                        display:none;
                    }
                }
            }
        }
    }

}
