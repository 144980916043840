@charset "UTF-8";

$component: componentClass("photocard");

.#{$component} {
    aspect-ratio:1/1;
    overflow:hidden;
    border-radius:tokenRadius(2);
    cursor:pointer;
    &__inner{
        position:relative;
        height:100%;
    }
    &__bg{
        position:absolute;
        img{
            width:100%;
            object-fit:cover;
            height:100%;
            display:block;
            border-radius:tokenRadius(2);
        }
    }
    &__content{
        position:relative;
        height:inherit;
        z-index:99;
    }
}