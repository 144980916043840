@charset "UTF-8";

@import "../../assets/scss/components/tokens/decolistbox1.style";

$component: componentClass("decolistbox1");
$cmp-tokens: ();

@if (variable-exists(zaux-decolistbox1-style-tokens)){
    $cmp-tokens : $zaux-decolistbox1-style-tokens;
}

.#{$component} {
    &__inner{
        padding-top: getCmpToken($cmp-tokens, "spacer-cmp-pt", tokenSpacer(6), 'layout' );
        padding-bottom: getCmpToken($cmp-tokens, "spacer-cmp-pb", tokenSpacer(9), 'layout');
    }
    &__deco{
        &--top{
            position:relative;
            margin-bottom: getCmpToken($cmp-tokens, "spacer-deco-pb", tokenSpacer(7), 'layout');
            .#{$component}__line{
                width:100%;
                padding:0;
            }
            .#{$component}__icon{
                position:absolute;
                left:50%;
                transform:translate(-50%,-50%);
                padding-left:tokenSpacer(3);
                padding-right:tokenSpacer(3);
                top:50%;
            }
        }
    }
    &__title{
        font-size:getCmpToken($cmp-tokens, "fs-title", tokenTypoSizeFs(10), 'layout');
        line-height:getCmpToken($cmp-tokens, 'lh-title', tokenTypoSizeLh(10), 'layout');
        font-weight: getCmpToken($cmp-tokens, 'fw-title', $zaux-typo-fw-semibold, 'layout' );
        text-align: getCmpToken($cmp-tokens, 'css-title-txtalign', center, 'layout');
        margin-bottom:getCmpToken($cmp-tokens, 'spacer-title-mb', tokenSpacer(1), 'layout');
    }
    &__subtitle{
        font-size:getCmpToken($cmp-tokens, 'fs-subtitle', tokenTypoSizeFs(6), 'layout');
        line-height: getCmpToken($cmp-tokens, 'lh-subtitle', tokenTypoSizeFs(6), 'layout');
        margin-bottom:getCmpToken($cmp-tokens, 'spacer-subtitle-mb', tokenSpacer(5), 'layout');
        text-align: getCmpToken($cmp-tokens, 'css-subtitle-txtalign', center, 'layout');
    }
    &__deco-rect{
        display:flex;
        flex-direction: column;
        line-height:0;
        justify-content:center;
        height:45px;
        text-align:center;
        width:75px;
        //padding:0 getCmpToken($cmp-tokens, 'spacer-decorect-px', tokenSpacer(2), 'layout');
        img{
            object-fit:contain;
            width:45px;
            height:25px;
            pointer-events:none;
        }
    }

    &.res{
        &--1{
            .#{$component}{
                &__inner{
                    @include media-breakpoint-down("md"){
                        padding-top: getCmpToken($cmp-tokens, "spacer-cmp-pt", tokenSpacer(6), 'layout-res1-md' );
                        padding-bottom: getCmpToken($cmp-tokens, "spacer-cmp-pb", tokenSpacer(6), 'layout-res1-md');
                    }
                }
            }
        }
    }

}