@charset "UTF-8";

//////////////////////////////////////////////////
// MISC - WIDTHS
//////////////////////////////////////////////////

.w-80{
    width:80%;
}
.w-75{
    width:75%;
}

.w-60{
    width:60%;
}

.w-50{
    width:50%;
}
.w-75{
    width:75%;
}
.w-60{
    width:60%;
}
.w-40{
    width:40%;
}
.w-30{
    width:30%;
}
.w-25{
    width:25%;
}

@each $k in tokenTypoBreakpoints() {
    @include media-breakpoint-up(#{$k}) {
        .w-#{$k}-80{
            width:80%;
        }
        .w-#{$k}-75{
            width:75%;
        }
        .w-#{$k}-60{
            width:60%;
        }
        .w-#{$k}-50{
            width:50%;
        }
        .w-#{$k}-40{
            width:40%;
        }
        .w-#{$k}-30{
            width:30%;
        }
        .w-#{$k}-25{
            width:25%;
        }
    }
}

.text-left{
    text-align:left;
}

.text-center{
    text-align:center;
}

.text-right{
    text-align:right;
}

@each $k in tokenTypoBreakpoints() {
    @include media-breakpoint-up(#{$k}) {
        .text-#{$k}-left{
            text-align:left;
        }
        .text-#{$k}-center{
            text-align:center;
        }
        .text-#{$k}-right{
            text-align:right;
        }
    }
}