@charset "UTF-8";

@import "../../assets/scss/components/tokens/productdescription.style";

$component: componentClass("productdescription");
$cmp-tokens: ();

@if (variable-exists(zaux-productdescription-style-tokens)){
    $cmp-tokens : $zaux-productdescription-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{

    color: getCmpToken($cmp-tokens, 'color-cmp-text', tokenColor("set1", "color4"), 'theme-light1');

    &__tab{
        &, button{ transition: border-color $zaux-transition-1, color $zaux-transition-1, opacity $zaux-transition-1; }
        button{
            //border-bottom-color: getCmpToken($cmp-tokens, 'color-tab-borderb', tokenColor("set1","color4"), 'theme-light1');
            opacity: getCmpToken($cmp-tokens, 'opacity-tab_inactive', 0.3, 'theme-light1');
            color:tokenColor("set1","color3");
        }
        &--active button, button[aria-selected="true"]{
            opacity: getCmpToken($cmp-tokens, 'opacity-tab_active', 1, 'theme-light1');
            //border-bottom-color: getCmpToken($cmp-tokens, 'color-tab-borderb_active', tokenColor('set1','color1'),'theme-light1');
        }
    }

    &__row{
        &--1{
            background-color:getCmpToken($cmp-tokens, 'color-row1-bg', tokenColor("set1","color2"), 'theme-light1');
        }
        &--2{
            background-color:getCmpToken($cmp-tokens, 'color-row2-bg', tokenColor("set1","color7"), 'theme-light1');
            color:tokenColor("set1","color3");
            position:relative;
            .#{$component}__details{
                background-color:getCmpToken($cmp-tokens, 'color-row2-bg', tokenColor("set1","color7"), 'theme-light1');
                border-top-color: getCmpToken($cmp-tokens, 'color-details-bordertop', tokenColor('set1','color1'), 'theme-light1');
                .#{$component-prefix}icon{
                    color: getCmpToken($cmp-tokens, 'color-detailsicon', tokenColor('set1','color1'), 'theme-light1');
                }
            }
        }
    }

}