@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("form");

@mixin form-fields-rules1{
    &:focus{
        outline:none;
    }

    input{
        &:focus{
            outline:none;
        }
    }

    //Sizes

    //Small is also default
    &--size-s,
    &:not([class*=--size]),
    &:is(input, select, textarea):not([class*=--size]){
        padding: getCmpToken($cmp-tokens, 'spacer-inputsmall-py', tokenSpacer(2), 'layout') getCmpToken($cmp-tokens, 'spacer-inputsmall-px', tokenSpacer(0), 'layout');
        font-size:getCmpToken($cmp-tokens, 'fs-inputsmall', tokenTypoSizeFs(3)+1, 'layout');
        line-height:getCmpToken($cmp-tokens, 'lh-inputsmall', tokenTypoSizeLh(3)+1,'layout');
        input{
            padding: getCmpToken($cmp-tokens, 'spacer-inputsmall-py', tokenSpacer(2), 'layout') getCmpToken($cmp-tokens, 'spacer-inputsmall-px', tokenSpacer(0), 'layout');
        }
        & + .#{$component}__field-icon{
            font-size: tokenIconSize(4);
        }
    }

    //Medium
    &--size-m{
        padding: getCmpToken($cmp-tokens, 'spacer-inputmedium-py', tokenSpacer(3), 'layout') getCmpToken($cmp-tokens, 'spacer-inputmedium-px', tokenSpacer(0), 'layout');
        font-size:getCmpToken($cmp-tokens, 'fs-inputmedium', tokenTypoSizeFs(6), 'layout');
        line-height:getCmpToken($cmp-tokens, 'lh-inputmedium', tokenTypoSizeLh(6),'layout');
        input{
            padding: getCmpToken($cmp-tokens, 'spacer-inputmedium-py', tokenSpacer(3), 'layout') getCmpToken($cmp-tokens, 'spacer-inputmedium-px', tokenSpacer(0), 'layout');
        }
        & + .#{$component}__field-icon{
            font-size: tokenIconSize(4);
        }
    }

    //Large
    &--size-l{
        padding: getCmpToken($cmp-tokens, 'spacer-inputlarge-py', tokenSpacer(4), 'layout') getCmpToken($cmp-tokens, 'spacer-inputlarge-px', tokenSpacer(0), 'layout');
        font-size:getCmpToken($cmp-tokens, 'fs-inputlarge', tokenTypoSizeFs(9), 'layout');
        line-height:getCmpToken($cmp-tokens, 'lh-inputlarge', tokenTypoSizeLh(9),'layout');
        input{
            padding: getCmpToken($cmp-tokens, 'spacer-inputlarge-py', tokenSpacer(4), 'layout') getCmpToken($cmp-tokens, 'spacer-inputlarge-px', tokenSpacer(0), 'layout');
        }
        & + .#{$component}__field-icon{
            font-size: tokenIconSize(5);
        }
    }

    //Radios
    &:is([type=radio]){
        position:relative;
        padding:0 !important;
        &,&.#{$component}__field--size-s{
            width:20px;
            height:20px;
            border-width: getCmpToken($cmp-tokens, 'css-radioinput-borderwidth', 1px, 'layout') !important;
        }
        &:after{
            content:"";
            position:absolute;
            pointer-events:none;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            height:10px;
            width:10px;
            opacity:0;
        }
        &:checked{
            &:after{
                opacity:1;
            }
        }
        &.#{$component}__field--size-m{
            width:30px;
            height:30px;
            padding:0;
            border-width: getCmpToken($cmp-tokens, 'css-radioinputmedium-borderwidth', 1px, 'layout') !important;
            &:after{
                height:15px;
                width:15px;
            }
        }
        &.#{$component}__field--size-l{
            width:40px;
            height:40px;
            padding:0;
            border-width: getCmpToken($cmp-tokens, 'css-radioinputlarge-borderwidth', 1px, 'layout') !important;
            &:after{
                height:20px;
                width:20px;
            }
        }
    }

    //Checkboxes
    &:is([type=checkbox]){
        position:relative;
        padding:0 !important;
        &,&.#{$component}__field--size-s{
            width:20px;
            height:20px;
            padding:0;
            border-width: getCmpToken($cmp-tokens, 'css-radioinput-borderwidth', 1px, 'layout') !important;
            
        }
        &.#{$component}__field--size-m{
            width:30px;
            height:30px;
            padding:0;
            border-width: getCmpToken($cmp-tokens, 'css-radioinputmedium-borderwidth', 1px, 'layout') !important;
        }
        &.#{$component}__field--size-l{
            width:40px;
            height:40px;
            padding:0;
            border-width: getCmpToken($cmp-tokens, 'css-radioinputlarge-borderwidth', 1px, 'layout') !important;
        }
    }

    //File
    &:is([type=file])::file-selector-button{
        margin-right:tokenSpacer(2);
        appearance:none;
        border-style:none;
        //padding: tokenSpacer(1) tokenSpacer(2);
    }

    //Variants
    &--type2, &--type3{
        border:none;
        &.#{$component}__field{
            &--size-s{
                padding: getCmpToken($cmp-tokens, 'spacer-input2small-py', tokenSpacer(2), 'layout') getCmpToken($cmp-tokens, 'spacer-input2small-px', tokenSpacer(3), 'layout');
                font-size:getCmpToken($cmp-tokens, 'fs-inputsmall', tokenTypoSizeFs(3)+1, 'layout');
                input{
                    padding: getCmpToken($cmp-tokens, 'spacer-input2small-py', tokenSpacer(2), 'layout') getCmpToken($cmp-tokens, 'spacer-input2small-px', tokenSpacer(3), 'layout');
                }
            }
            &--size-m{
                padding: getCmpToken($cmp-tokens, 'spacer-input2medium-py', tokenSpacer(4), 'layout') getCmpToken($cmp-tokens, 'spacer-input2medium-px', tokenSpacer(5), 'layout');
                font-size:getCmpToken($cmp-tokens, 'fs-inputmedium', tokenTypoSizeFs(6), 'layout');
                input{
                    padding: getCmpToken($cmp-tokens, 'spacer-input2medium-py', tokenSpacer(4), 'layout') getCmpToken($cmp-tokens, 'spacer-input2medium-px', tokenSpacer(5), 'layout');
                }
            }
            &--size-l{
                padding: getCmpToken($cmp-tokens, 'spacer-input2large-py', tokenSpacer(4), 'layout') getCmpToken($cmp-tokens, 'spacer-input2large-px', tokenSpacer(5), 'layout');
                font-size:getCmpToken($cmp-tokens, 'fs-inputlarge', tokenTypoSizeFs(9), 'layout');
                input{
                    padding: getCmpToken($cmp-tokens, 'spacer-input2medium-py', tokenSpacer(2), 'layout') getCmpToken($cmp-tokens, 'spacer-input2medium-px', tokenSpacer(5), 'layout');
                }
            }
        }
    }
    
    &:is(div){
        padding:0 !important;
    }
}

input,textarea,select{
    @include form-fields-rules1;
}

input[type=submit]{
    @extend .c-btn, .c-btn--size-m;
}

.#{$component} {
    &__field-wrap{
        &--has-icon{
            padding-left:0;
            padding-right:0;
            position:relative;
            .#{$component}{
                &__field{
                    &-icon{
                        position:absolute;
                        pointer-events:none;
                        &--r{
                            right:0;
                            top:tokenSpacer(2);
                        }
                        &--sm{
                            font-size:tokenIconSize(4);
                        }
                    }
                }
            }
        }
        &[class*=#{$component}__field-wrap--has-btn]{
            padding-left:0;
            padding-right:0;
            position:relative;
            .#{$component}__field-btn{
                position:absolute;
                height:100%;
            }
            &[class*=#{$component}__field-wrap--has-btn-r]{
                .#{$component}__field-btn{
                    top:0;
                    right:0;
                }
            }
        }

        .#{$component}__field{
            width:100%;
        }

        //Side label
        &--sidelabel{
            label{
                > * {
                    display:inline-block;
                    vertical-align:middle;
                }
            }
            .#{$component}__field-label{
                padding-left:getCmpToken($cmp-tokens, 'spacer-sidelabel-ml', tokenSpacer(1), 'layout');
            }
        }
    }

    &__field-label{
        font-size: getCmpToken($cmp-tokens, 'fs-fieldlabel', tokenTypoSizeFs(6), 'layout');
    }

    &__field{
        @include form-fields-rules1;
    }
}
