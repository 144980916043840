@charset "UTF-8";

@import "../../assets/scss/components/tokens/showcase1.style";

$component: componentClass("herostripe");
$cmp-tokens: ();

@if (variable-exists(zaux-herostripe-style-tokens)){
    $cmp-tokens : $zaux-herostripe-style-tokens;
}

.#{$component} {
    &__row{
        &--1{
            text-align:getCmpToken($cmp-tokens, 'css-row1-txtalign', right, 'layout');
        }
    }  
    &__icon{
        margin-bottom:getCmpToken($cmp-tokens, 'spacer-title-mb', tokenSpacer(2), 'layout');
    }
    &__title{
    }   

    &__text{
        position:relative;
        z-index:99;
    }

    &__img{
        position:absolute;
        right:0;
        bottom:0;
        top:0;
        height:100% !important;
        max-width:1140px !important;
        @include media-breakpoint-down("md"){
            opacity:0.3;
        }
    }

    //Modifiers
    &[class*=--reduced-pad]{
        .#{$component}__inner{
            padding-bottom:tokenSpacer(6) !important;
        }
    }

    //Responsive
    &.res{
        &--1{
            @include media-breakpoint-down("md"){
                text-align:getCmpToken($cmp-tokens, "css-cmp-txtalign", center, "layout-res1-md");
            }
            .#{$component-prefix}breadcrumbs{
                @include media-breakpoint-down("md"){
                    text-align:getCmpToken($cmp-tokens, "css-cmp-txtalign", center, "layout-res1-md");
                }
            }
            .#{$component}{
                &__title{
                    @include media-breakpoint-down("md"){
                        font-size: getCmpToken($cmp-tokens, 'fs-title', tokenTypoSizeFs(15), 'layout-res1-md');
                    }
                }
            }
        }
    }
}
