@charset "UTF-8";

$component: componentClass("card");

.#{$component} {
    &__media{
        border-radius:tokenRadius(2);
        aspect-ratio:4/3;
        img{
            width:100%;
            border-radius:tokenRadius(2);
            aspect-ratio:4/3;
        }
    }
}
