@charset "UTF-8";


@import "../../assets/scss/components/tokens/btn.style";

$component: componentClass("btn");
$cmp-tokens: ();

@if ($zaux-btn-style-tokens){
    $cmp-tokens : $zaux-btn-style-tokens;
}

@mixin c-btn-size($px) {
  text-align: center;

  .#{$component} {
    &__inner {
      font-size: rem($px);
      border: 1px solid transparent;
      height: 1em;
      border-radius: 0.5em;
      display: block;
    }
  }
}

[class*="#{$component}--"] {
  // Commented in favor of Bootstrap's `$line-height-base: 0;` (see src/assets/scss/abstracts/_variables.scss)
  font-size: 0;
  line-height: 0;

  &,
  &:link,
  &:visited,
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.#{$component} {

  display:inline-block;
  text-transform:uppercase;
  font-weight: getCmpToken($cmp-tokens, 'fw-btn', $zaux-typo-fw-regular, 'layout');
  letter-spacing: getCmpToken($cmp-tokens, 'ls-btn', tokenLetterSpacing(1), 'layout');
  text-align:center;
  border-width:1px;
  border-color:transparent;

  padding: getCmpToken($cmp-tokens, 'spacer-btnmedium-py', tokenSpacer(2), 'layout') getCmpToken($cmp-tokens, 'spacer-btnmedium-px', tokenSpacer(6), 'layout');
  font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(13), 'layout');
  line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeFs(13), 'layout');

  //Sizes

  //Small
  &--size-s{
    padding: getCmpToken($cmp-tokens, 'spacer-btnsmall-py', tokenSpacer(1), 'layout') getCmpToken($cmp-tokens, 'spacer-btnsmall-px', tokenSpacer(3), 'layout');
    font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(6), 'layout');
    line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(6), 'layout');
  }

  //Default and medium
  &--size-m{
    padding: getCmpToken($cmp-tokens, 'spacer-btnmedium-py', tokenSpacer(2), 'layout') getCmpToken($cmp-tokens, 'spacer-btnmedium-px', tokenSpacer(6), 'layout');
    font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(13), 'layout');
    line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeFs(13), 'layout');
  }

  //Large
  &--size-l{
    padding: getCmpToken($cmp-tokens, 'spacer-btnmedium-py', tokenSpacer(4), 'layout') getCmpToken($cmp-tokens, 'spacer-btnmedium-px', tokenSpacer(6), 'layout');
    font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(10), 'layout');
    line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(10), 'layout');
  }

  &--has-icon{
    padding: getCmpToken($cmp-tokens, 'spacer-btn-py', tokenSpacer(4), 'layout');
    border-width:2px;
    .#{$component}__label{
      font-size: getCmpToken($cmp-tokens, 'fs-btn', 14px, 'layout');
      line-height: getCmpToken($cmp-tokens, 'lh-btn', 14px, 'layout');
      padding-left:getCmpToken($cmp-tokens, 'spacer-label-pl', tokenSpacer(1), 'layout');
    }
    .#{$component-prefix}icon{
      //margin-right:getCmpToken($cmp-tokens, 'spacer-icon-mr', tokenSpacer(1), 'layout');
      font-size: getCmpToken($cmp-tokens, 'fs-icon-size-std', tokenIconSize(3), 'layout');
    }
  }

  &--icon{
    &-size-sm{
      padding: getCmpToken($cmp-tokens, 'spacer-btn-py', tokenSpacer(3), 'layout');
      font-size:getCmpToken($cmp-tokens, 'fs-icon-size-sm', tokenTypoSizeFs(3), 'layout');
    }
    &-size-md{
      padding: getCmpToken($cmp-tokens, 'spacer-btn-py', tokenSpacer(4), 'layout');
      font-size:getCmpToken($cmp-tokens, 'fs-icon-size-sm', tokenTypoSizeFs(9), 'layout');
    }
    &-size-lg{
      padding: getCmpToken($cmp-tokens, 'spacer-btn-py', tokenSpacer(5), 'layout');
      font-size:getCmpToken($cmp-tokens, 'fs-icon-size-sm', tokenTypoSizeFs(12), 'layout');
    }
  }

  @include media-breakpoint-down("sm"){
    &:not([class*=has-icon]){
      padding: tokenSpacer(2) tokenSpacer(5);
      font-size: getCmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(6), 'layout');
      line-height: getCmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(6), 'layout');
    }
  }

}
