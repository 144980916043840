@charset "UTF-8";

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
ul,
ol,
li,
dl,
dt,
dd,
address,
figure {
  padding: 0;
  margin: 0;
}

button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
}

a{
  text-decoration:none;
  color:inherit;
  &:hover{
    color:inherit;
  }
}

ul{
  list-style-type:none;
}
