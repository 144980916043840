@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("typo");

////////////////////////////////////////////////////
// Generic font-size CSS utilities
////////////////////////////////////////////////////

@include c-typo-text-rules("fs");
@include c-typo-text-rules-responsive("fs");

////////////////////////////////////////////////////
// Typography Block: Style1
////////////////////////////////////////////////////

$style-name: "style1";

// Paragraphs
@include c-typo-text-rules($style-name, 'p:not([class*="c-typo"])');
@include c-typo-text-rules-responsive($style-name, 'p:not([class*="c-typo"])');

// List items
@include c-typo-text-rules($style-name, 'li:not([class*="c-typo"])');
@include c-typo-text-rules-responsive($style-name, 'li:not([class*="c-typo"])');

[class*="#{$component}-"] {
  @at-root .#{$zaux-system-classname-loaded} & {
    transition: font-size $zaux-transition-1, line-height $zaux-transition-1;
  }
}

[class*="#{$component}-#{$style-name}"] {
  font-family: $zaux-font2;
  p,
  li {
    font-weight: $zaux-typo-fw-regular;

    @media (max-width: #{tokenBreakpoint(_high-xs)}) {
      word-break: break-word;
    }
  }

  strong {
    font-weight: $zaux-typo-fw-bold;
  }

  ul,
  ol {
    padding-left: tokenSpacer(5);
  }

  p:not(:last-child),
  li:not(:last-child),
  ul:not(:last-child),
  ol:not(:last-child) {
    margin-bottom: tokenSpacer(5);
  }

  ul ul,
  ol ol {
    padding-top: tokenSpacer(5);
  }

  ul li {
    list-style-type: none;
    &:before {
      content: "•";
      float: left;
      line-height: 1;

      margin: -0.25em 0 0 -0.55em;
      font-size: 2.7em;
    }
  }
  h3{
    margin-bottom:tokenSpacer(4);
  }
}

//This shit sucks but fuck it, gotta go fast.

$typoStyles : (
  "title-xl": (
    "fs" : 67px,
    "lh" : 68px
  ),
  "title1" : (
    "fs" : 62px,
    "lh" : 68px,
  ),
  "title2" : (
    "fs" : 52px,
    "lh" : 55px,
  ),
  "title3" : (
    "fs" : 40px,
    "lh" : 43px,
  ),
  "title4": (
    "fs" : 22px,
    "lh" : 28px
  ),
  "title5": (
    "fs" : 30px,
    "lh" : 40px
  ),
  "title6": (
    "fs" : 46px,
    "lh" : 57px
  ),
  "text1" : (
    "fs" : 18px,
    "lh" : 27px
  ),
  "text2" : (
    "fs" : 14px,
    "lh" : 21px
  ),
  "text3" : (
    "fs" : 16px,
    "lh" : 23px
  )
);

.#{$component}{
  @each $key, $styles in $typoStyles{
    &-#{$key}{
      font-size:map-get($styles, "fs");
      line-height:map-get($styles, "lh");
    }
  }
  @each $key, $styles in $typoStyles{
    @each $k in tokenTypoBreakpoints() {
      @include media-breakpoint-up($k){
        &-#{$k}-#{$key}{
          font-size:map-get($styles, "fs");
          line-height:map-get($styles, "lh");
        }
      }
    }
  }
}

////////////////////////////////////////////////////
// Fixes
////////////////////////////////////////////////////
@media (max-width: #{tokenBreakpoint(_mid-xs)}) {
  .c-typo-fs-18 {
    @include typoFix1();
  }

  .c-typo-style1-9 p:not([class*="c-typo"]) {
    @include typoFix2();
  }
}
