/*-- WOOCOMMERCE OVERRIDES & VARIABLES --*/
:root{
    --woocommerce: tokenColor("set1","color1");
    --wc-primary: tokenColor("set1","color1");
}

$btn-color: tokenColor("set1","color1");
$btn-txt-color: tokenColor("set1","white1");
$form-field-bg: tokenColor("set1","white1");
$inner-bg-color: tokenColor("set1","color2");


/*-- WOCOMMERCE CSS --*/

.woocommerce table.shop_table{
    border:none !important;
}

body.woocommerce-cart #zaux-content-inner{
    background-color:$inner-bg-color !important;
}

label[for="coupon_code"]{
    @include visually-hidden();
}

.woocommerce-notice.woocommerce-notice--info{
    &:before{
        display:none !important;
    }
}

.woocommerce-notice{
    font-family: $zaux-font2;
}


.woocommerce a.remove{
    display: block;
    font-size: 24px;
    height: 1em;
    width: 1em;
    text-align: center;
    line-height: 1;
    border-radius: 100%;
    color: tokenColor("set1","color1")!important;
    text-decoration: none;
    font-weight: 500;
    border: 0;
    font-family: sans-serif !important;
    padding-top: 0.1em;
    &:hover{
        background-color:tokenColor("set1","color1") !important;
    }
}

.woocommerce a.remove{
    line-height: 0.8em !important;
}

.woocommerce-cart .coupon{
    padding: tokenSpacer(1);
    border-radius:50px;
    background-color: $form-field-bg;
}

body.woocommerce-cart{
    background: tokenColor("set1","white1");
}

.woocommerce{
    color:tokenColor("set1","color4");
    #order_review_heading{
        padding-top:tokenSpacer(6);
    }
}

.woocommerce-info, .woocommerce-message, .woocommerce-error {
    font-family: $zaux-font2, sans-serif;
    background-color: #ffffff;
}

#add_payment_method #payment, .woocommerce-cart #payment, .woocommerce-checkout #payment{
    background-color:tokenColor("set1","color7") !important;
}

.woocommerce-info::before{
    color:tokenColor("set1","color1") !important;
}

.woocommerce-info{
    border-top-color:tokenColor("set1","color1") !important;
}


#add_payment_method table.cart td.actions .coupon .input-text, .woocommerce-cart table.cart td.actions .coupon .input-text, .woocommerce-checkout table.cart td.actions .coupon .input-text {
    width: 250px;
    height: 45px;
    line-height: 45px;
    padding: 0 20px !important;
    background-color: #ffffff !important;
    border: 0 !important;
    font-size: 11px !important;
    -webkit-border-radius: 23px;
    -moz-border-radius: 23px;
    -o-border-radius: 23px;
    -ms-border-radius: 23px;
    border-radius: 23px;
}
.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
    height: 45px;
    line-height: 45px;
    padding: 0 30px !important;
    -webkit-border-radius: 23px;
    -moz-border-radius: 23px;
    -o-border-radius: 23px;
    -ms-border-radius: 23px;
    border-radius: 23px;
    background-color: $btn-color !important;
    border: 0 !important;
    text-transform: uppercase;
    font-size: 12px !important;
    color: #ffffff !important;
    font-family: $zaux-font2, sans-serif;
    font-weight: 700;
}
.woocommerce #respond input#submit:hover, .woocommerce a.button:hover, .woocommerce button.button:hover, .woocommerce input.button:hover {
    background-color: lighten( $btn-color, $lighten-hover-factor) !important;
}

.woocommerce #zaux-content-wrapper button:not(.c-btn, [data-bs-toggle="tab"]),
.woocommerce .button:not(.c-btn, [data-bs-toggle="tab"]){
    transition: background-color $zaux-transition-1;
    @extend .c-btn, .c-btn--primary;
    border-radius:50px !important;
    line-height: 43px !important;
    background-color: $btn-color;
}

.cart_totals h2 {
    font-size: 30px;
    line-height: 40px;
    font-family: $zaux-font2, sans-serif;
    margin-bottom: 10px;
    font-weight: 700;
}
.woocommerce table.shop_table {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}
.woocommerce .cart-collaterals .cart_totals, .woocommerce-page .cart-collaterals .cart_totals {
    width: 35%;
}
.woocommerce-cart #shop-opening img {
    max-width: 55px;
    margin-bottom: 0 !important;
}
.woocommerce table.shop_table * {
    font-family: $zaux-font2, sans-serif;
}
.woocommerce-cart table.cart th {
    background-color: #ffffff;
    color: tokenColor("set1","color1");
    font-weight: 700;
}
.woocommerce-cart .product-name a {
    font-weight: 700;
    text-decoration: none;
}
.woocommerce-cart .product-price, 
.woocommerce-cart .product-subtotal {
    font-weight: 700;
}

.woocommerce-page .cart-collaterals th {
    background-color: #ffffff;
    font-weight: 700;
}
.woocommerce-page .cart-collaterals .order-total {
    font-weight: 700;
}

.woocommerce a.remove {
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -o-border-radius: 24px;
    -ms-border-radius: 24px;
    border-radius: 24px;
    line-height: 21px;
    background-color: #ffffff;
}
.checkout-button {
    background-color:$btn-color;
}
#product-left {
    width: 43%;
    padding-right: 40px;
    float: left;
}
#product-left img {
    background-color: tokenColor("set1","color1");
    box-shadow: inset 0px 0px 150px #09568e;
    color: #ffffff;
    /*padding-top: 60px;*/
    width: 100%;
}
#product-right {
    width: 57%;
    float: left;
}
#product-right h1 {
    font-size: 40px;
    margin-bottom: 28px;
    line-height: 46px;
    font-weight: 700;
    font-family: $zaux-font2, sans-serif;
}
.product-sku {
    background-color: #ffffff;
    padding: 0 40px 0 15px;
    font-size: 13px;
    color: #111111;
    font-family: $zaux-font2, sans-serif;
    line-height: 30px;
    padding-bottom: 2px;
}
.product-sku strong {
    font-weight: 700;
    color: tokenColor("set1","color1");
}

#product-infos p {
    margin-bottom: 20px;
    padding-top: 20px;
    font-size: 16px;
    font-family: $zaux-font2, sans-serif;
}
#product-infos p span {
    width: 170px;
}
#product-infos p strong {
    font-weight: 700;
}
#product-infos hr {
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
    border-bottom: 1px solid #C1C0C0 !important;
}
#product-infos p del {
    font-size: 30px;
    color: #868686;
    font-weight: 700;
    margin-right: 30px;
}
#product-infos p ins {
    text-decoration: none;
    color: tokenColor("set1","color1");
    font-weight: 700;
    font-size: 35px;
}

.woocommerce a.button.zk-add-to-cart-button {
    display: block;
    width: 70% !important;;
    margin: 0 15% 0 !important;
    text-align: center !important;
    height: 56px !important;
    line-height: 56px !important;
    -webkit-border-radius: 56px !important;
    -moz-border-radius: 56px !important;
    -o-border-radius: 56px !important;
    -ms-border-radius: 56px !important;
    border-radius: 56px !important;
    font-size: 14px !important;
}

.woocommerce table.shop_table, 
.woocommerce-cart .cart-collaterals .cart_totals table {
    border-left: 0;
    border-right: 0;
}
.woocommerce table.shop_table.woocommerce-cart-form__contents {
    border-top: 0;
}

.no-product-text {
    font-family: $zaux-font2, sans-serif;
}
.no-product-text em {
    font-style: italic;
}

.single-discount {
    width: 77px;
    height: 77px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -o-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%;
    background-color: #000000;
    display: block;
    float: right;
    font-size: 28px;
    line-height: 77px;
    color: #ffffff;
    font-weight: 700;
    font-family: $zaux-font2, sans-serif;
    text-align: center;
    margin-left: 20px;
}

.woocommerce-terms-and-conditions-wrapper{
    margin-bottom:tokenSpacer(6);
}

/* RESPONSIVE */
@media (max-width: 1200px) {
    #product-infos p {
        font-size: 14px;
    }
    #product-infos p span {
        width: 150px;
    }
    #product-infos p del {
        font-size: 21px;
        margin-right: 15px;
    }
    #product-infos p ins {
        font-size: 25px;
    }

    .slider-bg {
        height: 450px;
    }
    #shop_main-slider .slider-bg img {
        max-width: 800px;
    }

    .c-shopbox .single-in-list {
        width: 33.333%;
    }
}
@media (max-width: 1100px) {
    .c-shopbox .strengths .s-str {
        width: 50%;
        margin-top: 30px;
    }
    #shop_main-slider .slider-bg .title {
        font-size: 35px;
        line-height: 32px;
    }
    #shop_main-slider .slider-bg p {
        font-size: 17px;
        line-height: 18px;
        max-width: 250px;
    }
}
@media (max-width: 950px) {
    .asl_w_container.asl_w_container_1 {
        display: none;
    }
}
@media (max-width: 900px) {
    #product-left, #product-right, .prod-tab-content {
        width: 100%;
        float: none;
        padding-right: 0;
    }
    .ask-for-informations {
        width: 100%;
        float: none;
    }
    #product-left {
        margin-bottom: 30px;
    }
    #prod-sp-space {
        display: none !important;
    }
    .prod-tab-content {
        margin-bottom: 30px;
    }

    #shop_main-slider .slider-bg img {
        max-width: 650px;
    }
    .slider-bg {
        height: 400px;
    }
    .slider-bg > div > * {
        z-index: 3;
    }
    .slider-bg > div > img {
        z-index: 2;
    }
    .slider-bg > div .title, 
    .slider-bg > div .suptitle, 
    .slider-bg > div p {
        position: relative;
    }
    #shop_main-slider .slider-bg .suptitle {
        margin-bottom: 20px;
    }
}
@media (max-width: 850px) {
    .c-shopbox .single-in-list {
        width: 50%;
    }
}
@media (max-width: 700px) {
    .c-shopbox .strengths .s-str {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .c-shopbox .strengths .s-str div {
        padding: 25px 20px;
    }
    .c-shopbox .strengths .title {
        font-size: 14px;
        line-height: 16px;
    }
    .c-shopbox .strengths .desc {
        font-size: 13px;
        line-height: 15px;
    }
    .c-shopbox {
        padding-top: 45px;
        padding-bottom: 35px;
    }
    #shop_categories .inner-wrapper {
        padding-top: 45px;
        padding-bottom: 55px;
    }
    #add_payment_method table.cart td.actions .coupon .input-text, .woocommerce-cart table.cart td.actions .coupon .input-text, .woocommerce-checkout table.cart td.actions .coupon .input-text {
        width: 48%;
    }
    .woocommerce #content table.cart td.actions .coupon .button.alt, .woocommerce #content table.cart td.actions .coupon .input-text+.button, .woocommerce table.cart td.actions .coupon .button.alt, .woocommerce table.cart td.actions .coupon .input-text+.button, .woocommerce-page #content table.cart td.actions .coupon .button.alt, .woocommerce-page #content table.cart td.actions .coupon .input-text+.button, .woocommerce-page table.cart td.actions .coupon .button.alt, .woocommerce-page table.cart td.actions .coupon .input-text+.button {
        text-overflow: ellipsis;
        overflow: hidden;
    }
    #shop-opening.cart-variant h1 {
        font-size: 25px;
        line-height: 30px;
    }
    .cart_totals h2 {
        font-size: 20px;
        line-height: 25px;
    }
    .woocommerce .cart-collaterals .cart_totals, .woocommerce-page .cart-collaterals .cart_totals {
        width: 100%;
    }
    #shop_categories .inner-wrapper > .title,
    #shop_categories .inner-wrapper > .uptitle,
    #shop_categories .inner-wrapper > .text,
    .here-slider-cont .inner-wrapper > .title {
        margin-left: 5%;
        margin-right: 5%;
        padding-left: 25px;
        padding-right: 25px;
        border-top: 0;
        padding-top: 0;
    }
    #shop_categories .suptitle {
        margin-bottom: 20px;
    }
    #shop_categories .inner-wrapper > .title {
        font-size: 25px;
        line-height: 28px;
    }
    #shop_categories .inner-wrapper > .text {
        font-size: 17px;
        line-height: 20px;
    }
    #shop_categories .inner-wrapper, 
    .here-slider-cont .inner-wrapper {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    #shop_categories .slider-container,
    .here-slider-cont .slider-container {
        margin-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
    }

    #product-infos p span, 
    #product-infos p strong {
        display: block;
        width: 100%;
    }
    
    #product-infos p span {
        margin-bottom: 5px;
    }
    #product-infos p {
        padding-top: 10px;
        margin-bottom: 10px;
    }
    #product-right h1 {
        font-size: 25px;
        line-height: 28px;
    }
    .single-discount {
        width: 55px;
        height: 55px;
        font-size: 18px;
        line-height: 55px;
        margin-right: -20px;
    }
    .product-sku {
        margin-bottom: 20px;
        width: max-content;
        padding: 0 15px;
    }
}
@media (max-width: 550px) {
    .c-shopbox .strengths img {
        width: 55px;
        margin-bottom: 10px;
    }
    .c-shopbox .strengths .s-str {
        width: 100%;
    }
    .logo-top {
        width: 150px;
        height: 25px;
        background-size: 150px 25px;
    }
    #user-menu {
        top: 0;
    }
    #header-top {
        padding-bottom: 15px;
    }
    #header {
        padding-top: 22px;
    }
    .shop-header {
        min-height: 135px;
    }
    .back-to-website {
        top: 18px;
    }
    #shop-opening {
        margin-top: 135px;
    }

    #shop_main-slider .slider-bg img {
        display: none !important;
    }
    .slider-bg {
        height: 350px;
    }
    #shop_main-slider {
        padding-top: 30px;
        padding-bottom: 70px;
    }
    #shop_main-slider .inner-wrapper {
        margin: 0;
        padding: 0;
        width: 100%;
    }
    #shop_main-slider .slick-dots {
        bottom: -40px;
    }
    #shop_main-slider .slider-bg p {
        max-width: 100%;
        font-size: 15px;
        line-height: 17px;
    }
    #shop_main-slider .slider-bg .title {
        font-size: 25px;
        line-height: 27px;
    }
    #shop_main-slider .slider-bg .discount {
        margin-left: -25px;
    }
    #shop_main-slider .slider-bg .discount+.cta {
        left: 125px;
    }
    .c-shopbox .single-in-list {
        width: 100%;
    }

    #shop-opening img.cat-icon {
        width: 53.5px;
    }
    #shop-opening h1 {
        font-size: 30px;
        line-height: 33px;
    }

    #breadcrumbs {
        margin-bottom: 20px;
    }
    #shop-opening {
        padding-bottom: 35px;
    }

    .c-shopbox.archive-variant {
        padding-top: 30px;
        padding-bottom: 45px;
    }
}

.c-shopbox .zui-tile > .zui-tile__inner .title {
    height: 89px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.cart-collaterals .cross-sells {
    display: none !important;
}

@media (max-width: 500px) {
    body.single-product .woocommerce #middle .inner-wrapper {
        padding-left: 7px;
        padding-right: 7px;
    }
    #breadcrumbs {
        text-align: left;
    }
}

@media (max-width: 450px) {
    #shop-opening h1, 
    .ask-for-informations h3 {
        font-size: 24px;
        line-height: 26px;
    }
}

.slider .zui-tile {
    position: relative;
}

.c-shopbox .zui-tile > .zui-tile__inner .title {
    font-size: 22px;
    line-height: 21px;
    height: 84px;
}

.exception-cavi-paziente .title {
    font-size: 17px !important;
    line-height: 18px !important;
    height: 90px !important;
    -webkit-line-clamp: 5 !important;
    line-clamp: 5 !important;
}