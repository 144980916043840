@charset "UTF-8";

@import "../../assets/scss/components/tokens/filterlist1.style";

$component: componentClass("filterlist1");
$cmp-tokens: ();

@if (variable-exists(zaux-filterlist1-style-tokens)){
    $cmp-tokens : $zaux-filterlist1-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color:tokenColor("set1","color7");
    border-radius:tokenRadius(2);
    color:tokenColor("set1","color3");
}