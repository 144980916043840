@charset "UTF-8";

@import "../../assets/scss/components/tokens/showcase2.style";

$component: componentClass("showcase2");
$cmp-tokens: ();

@if ($zaux-showcase2-style-tokens){
    $cmp-tokens : $zaux-showcase2-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{

    color: tokenColor("set1","color3");
    background-color:tokenColor("set1", "color2" );

    &--theme-variant1{
        background-color:tokenColor("set1", "color2" );
    }

    &__head{
        //border-top: 7px solid getCmpToken($cmp-tokens, 'color-head-bordertop', tokenColor('set1','color1'), 'theme-light1');
    }

    &__title{
        color: tokenColor("set1","color3");
    }

}