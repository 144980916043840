@charset "UTF-8";

@import "../../assets/scss/components/tokens/herostripe.style";

$component: componentClass("herostripe");
$cmp-tokens: ();

@if ($zaux-herostripe-style-tokens){
    $cmp-tokens : $zaux-herostripe-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color:tokenColor("set1","color7");
    &__title{
        color:tokenColor("set1","color3");
    }
    &__icon{
        color:tokenColor("set1","color3");
    }
}