@charset "UTF-8";

$component: componentClass("footer");
$cmp-tokens: ();

@if ($zaux-footer-style-tokens){
    $cmp-tokens : $zaux-footer-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--light1 .#{$component} {
    background-color: tokenColor('set1', 'color7');
    color: tokenColor('set1', 'color3');
    border-top-style: getCmpToken($cmp-tokens, 'css-cmp-bordertopstyle', solid, 'theme-light1');
    border-top-color: getCmpToken($cmp-tokens, 'color-cmp-bordertop', transparent, "theme-light1");
    &__row{
        &--1{
            padding-bottom:getCmpToken($cmp-tokens, 'spacer-row1-pb', tokenSpacer(4), 'layout');
            border-bottom: 1px solid tokenColor("set1","color3");
        }
    }
    &__social{
        &-item{
            border:1px solid tokenColor("set1","color3");
            .#{$component-prefix}icon{
                color:tokenColor("set1","color3");
            }
        }
    }
    &__credits{
        background-color:getCmpToken($cmp-tokens, 'color-footer-credits-bg', tokenColor('set1', 'white1'), 'theme-light1');
        color: getCmpToken($cmp-tokens, 'color-footer-credits-text', tokenColor('set1', 'color2'), 'theme-light1');
        .#{$component-prefix}icon{
            color:getCmpToken($cmp-tokens, 'color-footer-credits-icon', tokenColor('set1','color2'), 'theme-light1');
        }
    }
}
