@charset "UTF-8";

@import "../../assets/scss/components/tokens/newsletterbox1.style";

$component: componentClass("newsletterbox1");
$cmp-tokens: ();

@if (variable-exists(zaux-newsletterbox1-style-tokens)){
    $cmp-tokens : $zaux-newsletterbox1-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color: tokenColor("set1","color6");
    color: tokenColor("set1","color3");
    &__title{
        color:tokenColor("set1","color3");
    }
    &__subtitle{
        color:tokenColor("set1","color3");
    }
    a{
        text-decoration:underline;
        color: getCmpToken($cmp-tokens, 'color-anchors', tokenColor('set1','white1'), 'theme-light1');
    }
}