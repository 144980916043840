@charset "UTF-8";

@import "../../assets/scss/components/tokens/header.style";

$component: componentClass("header");
$cmp-tokens: ();

@if (variable-exists(zaux-header-style-tokens)){
    $cmp-tokens : $zaux-header-style-tokens;
}

.#{$component} {
    position:relative;
    z-index:tokenZindex(header);
    &__badge{
        border-radius:tokenRadius("full");
        &--size{
            &-sm{
                height:24px;
                min-width:24px;
                font-size:getCmpToken($cmp-tokens, 'fs-badge', tokenTypoSizeFs(3)+1, 'layout');
                padding-top:tokenSpacer(1);
                text-align:center;
                display:flex;
                align-items:center;
                flex-direction:column;
                span{
                    line-height:0;
                }
            }
        }
    }
    &__logo{
        display:block;
    }
    &__menu{
        position:relative;
        ul{
            display:flex;
            gap: tokenSpacer(6);
            align-items:center;
            .#{$component}__menu-item{
                font-size: tokenTypoSizeFs(10);
                display:inline-block;
            }
            .#{$component}__menu-sub{
                display:none;
            }
        }
    }
    &__megamenu{
        display:none;
        position:absolute;
        left:0;
        right:0;
        top:0;
        width:100%;
        &[class*=--show]{
            display:block;
        }
    }
    &__language-selector{
        font-size: tokenTypoSizeFs(3);
        select{
            border:none !important;
        }
    }
    &__social{
        li{
            padding:tokenSpacer(1);
            border-radius:9999em;
            height:28px;
            width:28px;
            border-width:1px;
            position:relative;
            .#{$component-prefix}icon{
                position:absolute;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
            }
        }
    }
    &__quick-links{

        &-item{
            font-size:getCmpToken($cmp-tokens, 'fs-quick-links-item', rem(tokenTypoSizeFs(3)+1), 'layout');
            .#{$component-prefix}-icon{
                font-size:getCmpToken($cmp-tokens, 'fs-badge-icon', tokenIconSize(2), 'layout');
            }
            .#{$component}__badge{
                margin-left:getCmpToken($cmp-tokens, "spacer-badge-ml", tokenSpacer(1), 'layout');
            }
        }
    }

    &__row{
        &--1,&--2{
            padding: getCmpToken($cmp-tokens, 'spacer-rows-py', tokenSpacer(5),'layout') 0px;
        }
    }

    //Responsive
    &.res{
        &--1{
            @include media-breakpoint-down("lg"){
                .#{$component}{
                    &__inner{
                        //padding: 0 getCmpToken($cmp-tokens, 'spacer-inner-py', 0 tokenSpacer(6), 'layout-res1-lg');
                    }
                }
            }
            @include media-breakpoint-down("md"){
                .#{$component}{
                    &__row{
                        &--1{
                            border-bottom:none;
                        }
                        &--2{
                            display:none;
                        }
                    }
                }
            }
            .#{$component}__logo{
                @include media-breakpoint-down("sm"){
                   //width:getCmpToken($cmp-tokens, "css-logo-width", 250px, 'layout-res1-sm' );
                   width:80px;
                }
            }
            @media(max-width: #{tokenBreakpoint(_high-xs)}){
                .#{$component}{
                    &__inner{
                       //padding: 0 getCmpToken($cmp-tokens, "spacer-inner-px", 0 tokenSpacer(2),'layout-res1-high-xs');
                    }
                    &__logo{
                        width:80px;
                        height:auto;
                    }
                    &__row{
                        &--1{
                            .#{$component}__col--1{
                                display:flex;
                                align-items: center;
                            }
                        }
                        &--1,&--2{
                            padding: getCmpToken($cmp-tokens, 'spacer-rows-py', tokenSpacer(3), 'layout-res1-high-xs') 0px;
                        }
                    }
                }
            }
        }
    }
}
