@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("slider");

// /swiper/modules/pagination/pagination.scss

:root {
  --swiper-theme-color: #{tokenColor("set1", "cyan1")};
  --swiper-pagination-bullet-inactive-color: #{tokenColor("set1", "gray1")};
  --swiper-pagination-bullet-horizontal-gap: #{tokenSpacer(2)};
  --swiper-pagination-bullet-vertical-gap: 0;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
}

.#{$component} {
  &__wrapper {
    overflow: hidden;
    position: relative;
  }

  &--fx-1 {
    .swiper-wrapper {
      // display: flex;
      transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
    }
  }

  &--gx-3 {
    $gx: tokenSpacer(3);

    .#{$component}__instance {
      padding-right: $gx * 2;
      > .swiper-wrapper {
        margin-left: $gx;
      }
    }
  }

  .#{$component}__pagination{
      --swiper-pagination-bullet-horizontal-gap: #{getCmpToken($cmp-tokens, 'spacer-bullet-mx', tokenSpacer(1), 'layout')};
      --swiper-pagination-bullet-size:#{getCmpToken($cmp-tokens, 'size-bullet', 10px, 'layout')};
  }

  .swiper-slide{
    height:auto;
    > *{
      height:100%;
    }
  }
}
