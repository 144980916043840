@charset "UTF-8";

$component: componentClass("alertbanner");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color:tokenColor("set1","color1");
    color:tokenColor("set1","white1");
}