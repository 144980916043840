$zaux-btn-style-tokens:(
  layout: (
    fs-btn: 18px,
    fw-btn: $zaux-typo-fw-semibold
  ),
  theme-light1: (
    color-cmp-bg: tokenColor('set1','color1'),
    color-cmp-txtcolor: tokenColor('set1','white1')
  )
);
