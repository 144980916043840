@charset "UTF-8";

@import "../../assets/scss/components/tokens/cardicon1.style";

$component: componentClass("cardicon1");
$cmp-tokens: ();

@if ($zaux-cardicon1-style-tokens){
    $cmp-tokens : $zaux-cardicon1-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color: getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor("set1", "color6"), 'theme-light1');
    color: getCmpToken($cmp-tokens, 'color-cmp-textcolor', tokenColor("set1", "color3"), 'theme-light1');
    border-radius:tokenRadius(getCmpToken($cmp-tokens, 'radius-cmp', 2, 'theme-light1'));

    &__icon{
        color: getCmpToken($cmp-tokens, 'color-icon', tokenColor("set1", "color3"), "theme-light1");
    }
    &__title2{
        font-family: $zaux-font3;
    }
    &__subtitle{
        font-family:$zaux-font2;
    }
}