@charset "UTF-8";

@import "../../assets/scss/components/tokens/offcanvasnav.style";

$component: componentClass("offcanvasnav");
$cmp-tokens: ();

@if (variable-exists(zaux-offcanvasnav-style-tokens)){
    $cmp-tokens : $zaux-offcanvasnav-style-tokens;
}

.#{$component} {
    
    position:fixed;
    z-index:tokenZindex('offcanvas');
    left:-#{getCmpToken($cmp-tokens, 'css-cmp-width', 300px, 'layout')};
    width: getCmpToken($cmp-tokens, 'css-cmp-width', 300px, 'layout');
    transition:left $zaux-transition-1;
    top:0;
    right:0;
    height:100%;
    overflow:auto;

    &--open{
        left:0;
    }

    &__inner{
        padding-top: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(6), 'layout');
    }

    &__open-submenu{
        position:absolute;
        right:0;
        top:0;
        transform-origin: center;
        >*{
            pointer-events:none;
        }
        i{
            transform-origin:center;
            transition: all $zaux-transition-1;
            pointer-events:none;
        }
        &[class*=--active]{
            i{
                transform:rotate(180deg);
            }
        }
    }

    &__submenu{
        display:none;
        &[class*=--show]{
            display:block;
        }
    }

    &__menu{
        ul{
            li{
                a{
                    display:block;
                    padding: getCmpToken($cmp-tokens, 'spacer-menu-entry-pxy', tokenSpacer(4), 'layout');
                }
            }
        }
    }

}