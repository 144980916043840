@charset "UTF-8";

@import "../../assets/scss/components/tokens/showcase1.style";

$component: componentClass("showcase1");
$cmp-tokens: ();

@if ($zaux-showcase1-style-tokens){
    $cmp-tokens : $zaux-showcase1-style-tokens;
}

.#{$component} {
    &__inner{
        padding-top: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(8b), 'layout');
        padding-bottom: getCmpToken($cmp-tokens, 'spacer-inner-pb', tokenSpacer(9), 'layout');
        .#{$component}{
            &__head{
                text-align:getCmpToken($cmp-tokens,'css-head-textalign', center, 'layout');
                padding-bottom:getCmpToken($cmp-tokens, 'spacer-head-mb', tokenSpacer(7), 'layout');
                .#{$component}{
                    &__pre-title{
                        font-size: getCmpToken($cmp-tokens, 'fs-pretitle', tokenTypoSizeFs(6), 'layout' );
                        line-height: getCmpToken($cmp-tokens, 'lh-pretitle', tokenTypoSizeLh(9b), 'layout');
                        margin-bottom: getCmpToken($cmp-tokens, 'spacer-pretitle-mb', tokenSpacer(7), 'layout');
                        letter-spacing: getCmpToken($cmp-tokens, 'ls-pretitle', tokenLetterSpacing(1), 'layout');
                        text-transform:uppercase;
                    }
                    &__title{
                        //font-size: getCmpToken($cmp-tokens, 'fs-title', tokenTypoSizeFs(16), 'layout');
                        //line-height: getCmpToken($cmp-tokens, 'lh-title', tokenTypoSizeLh(16), 'layout');
                        margin-bottom:getCmpToken($cmp-tokens, 'spacer-title-mb', tokenSpacer(4), 'layout');
                        font-weight: getCmpToken($cmp-tokens, 'fw-title', $zaux-typo-fw-bold, 'layout');
                    }
                    &__subtitle{
                        font-size: getCmpToken($cmp-tokens, 'fs-subtitle', tokenTypoSizeFs(13), 'layout');
                        line-height: getCmpToken($cmp-tokens, 'lh-subtitle', tokenTypoSizeLh(6), 'layout');
                    }
                }
            }
        }

    }   

    &--nopad{
        .#{$component}__inner{
            padding:0;
        }
    }

    &.res{
        &--1{
            .#{$component}{
                &__pre-title{
                    @include media-breakpoint-down("md"){
                        margin-bottom:getCmpToken($cmp-tokens, 'spacer-head-mb', tokenSpacer(4), 'layout-res1-md');
                    }
                }
                &__inner{
                    .#{$component-prefix}slider__instance{
                        @include media-breakpoint-down("md"){
                            padding-bottom: getCmpToken($cmp-tokens, 'spacer-sliderinner-pb', tokenSpacer(8), 'layout-res1-md');
                        }
                    }
                    @include media-breakpoint-down("md"){
                        padding-top: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(6), 'layout-res1-md');
                        padding-bottom: getCmpToken($cmp-tokens, 'spacer-inner-pb', tokenSpacer(6), 'layout-res1-md');
                    }
                }
            }
        }
    }

}
