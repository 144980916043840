@charset "UTF-8";

@import "../../assets/scss/components/tokens/textblock.style";

$component: componentClass("textblock");
$cmp-tokens: ();

@if ($zaux-textblock-style-tokens){
    $cmp-tokens : $zaux-textblock-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    color:getCmpToken($cmp-tokens, 'color-cmp-txtcolor', tokenColor("set1", "color4"), "theme-light1");
    &__title{
        color: getCmpToken($cmp-tokens, 'color-title', tokenColor('set1','color1'), 'theme-light1');
    }
}