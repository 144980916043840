@charset "UTF-8";


$component: componentClass("configurator");
////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    &__head{
        background-color:tokenColor("set1","color3");
        color:tokenColor("set1","white1");
        border-top-left-radius:tokenRadius(2);
        border-top-right-radius:tokenRadius(2);
        position:relative;
        &:after{
            height:12px;
            width:100%;
            bottom:-12px;
            left:0;
            right:0;
            content:"";
            @include tentPattern();
            background-repeat:repeat-x;
            background-position:center;
            position:absolute;
            display:block;
        }
    }
    &__body{
        background-color:tokenColor("set1","color6");
        color:tokenColor("set1","color3");
        border-bottom-left-radius:tokenRadius(2);
        border-bottom-right-radius:tokenRadius(2);
    }
    &__step-selection{
        border-radius:tokenRadius(1);
        border:1px solid tokenColor("set1","color3");
    }
    &__progress{
        &-track{
            background-color:tokenColor("set1","white1");
        }
        &-indicator{
            background-color:tokenColor("set1","color3");
        }
    }
    &__steps-indicator{
        &-total{
            opacity:0.5;
        }
    }
}