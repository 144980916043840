@charset "UTF-8";

@import "../../assets/scss/components/tokens/cardicon1.style";

$component: componentClass("cardicon1");
$cmp-tokens: ();

@if ($zaux-cardicon1-style-tokens){
    $cmp-tokens : $zaux-cardicon1-style-tokens;
}

.#{$component} {
    display:flex;
    flex-direction:column;
    justify-content:center;
    &__title2{
        font-size:tokenTypoSizeFs(14b);
    }
    &__inner{
        padding: getCmpToken($cmp-tokens, 'spacer-inner-pxy', tokenSpacer(6), 'layout');
        text-align:getCmpToken($cmp-tokens, 'css-inner-txtalign', center, 'layout');
        .#{$component}__head{
            .#{$component}__icon{
                margin-bottom:getCmpToken($cmp-tokens, 'spacer-head-icon-mb', tokenSpacer(6), 'layout');
            }
        }
        .#{$component}__body{
            margin-bottom:getCmpToken($cmp-tokens, 'spacer-body-mb', tokenSpacer(5), 'layout');
            .#{$component}__title{
                font-weight: getCmpToken($cmp-tokens, 'fw-body-title', $zaux-typo-fw-medium, 'layout');
                text-transform:uppercase;
                @include tokenTypoStyle(getCmpToken($cmp-tokens, 'ctypo-body-title',14b,'layout'));
            }
        }
    }
}
