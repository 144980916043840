@charset "UTF-8";

@import "../../assets/scss/components/tokens/showcase1.style";

$component: componentClass("productdescription");
$cmp-tokens: ();

@if (variable-exists(zaux-productdescription-style-tokens)){
    $cmp-tokens : $zaux-productdescription-style-tokens;
}

.#{$component} {
    &__tabs{
        font-size:0;
        margin-left:-#{getCmpToken($cmp-tokens, 'spacer-tab-ml', tokenSpacer(4), 'layout')};
        .#{$component}__tab{
            display:inline-block;
            margin-left:getCmpToken($cmp-tokens, 'spacer-tab-ml', tokenSpacer(4), 'layout');
            min-width: getCmpToken($cmp-tokens, 'css-tab-minwidth', 240px, 'layout');
            button{
                width:100%;
                text-align:left;
                display:block;
                padding: getCmpToken($cmp-tokens, 'spacer-tab-py', tokenSpacer(6), 'layout') 0;
                font-size:getCmpToken($cmp-tokens, 'fs-tab', tokenTypoSizeFs(14), 'layout');
                font-weight:getCmpToken($cmp-tokens, 'fw-tab', $zaux-typo-fw-bold, 'layout');
                //border-bottom-width: getCmpToken($cmp-tokens, 'css-tab-borderbw', 9px, 'layout');
                //border-bottom-style:solid;
            }
        }
    }
    &__inner{
        .#{$component}{
            &__row{
                &--2{
                    padding: getCmpToken($cmp-tokens, 'spacer-row2-py', tokenSpacer(7), 'layout') 0;
                    @include tokenTypoStyle(10);
                    .#{$component}__content{
                        display:none;
                        &.active{
                            display:block;
                        }

                        * + .#{$component}__details{
                            margin-top: getCmpToken($cmp-tokens, 'spacer-details-mt', tokenSpacer(6), 'layout');
                        }

                        .#{$component}__details{
                            padding:getCmpToken($cmp-tokens, 'spacer-details-py', tokenSpacer(5), 'layout') 0;
                            border-top-width: getCmpToken($cmp-tokens, 'css-details-bordertw', 2px, 'layout');
                            border-top-style:solid;
                            list-style-type:none;

                            &-item{
                                margin-bottom: getCmpToken($cmp-tokens, 'spacer-detailitem-mb', tokenSpacer(4), 'layout');
                                list-style-type:none;
                                &:last-child{
                                    margin-bottom:0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.res{
        &--1{
            .#{$component}{
                &__inner{
                    .#{$component}__row{
                        &--2{
                            @include media-breakpoint-down("md"){
                                padding: getCmpToken($cmp-tokens, 'spacer-row2-py', tokenSpacer(6), 'layout-res1-md') 0;
                            }
                        }
                    }
                }
            }   
        }
    }
}