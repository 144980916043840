@charset "UTF-8";

$component: componentClass("configurator");

.#{$component} {
    display:none;
    &[class*=--loaded]{
        display:block;
    }
    &__step{
        display:none;
        &.--active{
            display:block;
        }
    }
    &__step-selection{
        position:relative;
        transition:all $zaux-transition-1;
        .#{$component}__step-label{
            position:absolute;
            left:0;
            right:0;
            bottom:-#{tokenSpacer(2) - 0.5};
        }
        &:hover{
            transform:scale(1.05);
        }
    }
    &__progress{
        &-track{
            height:5px;
            border-radius:tokenRadius("pill");
        }
        &-indicator{
            height:100%;
            transition:all $zaux-transition-1;
            border-radius:tokenRadius("pill");
            width:0%;
        }
    }
    &__backbtn{
        opacity:0;
        transition:all $zaux-transition-1;
        &.--visible{
            opacity:1;
        }
    }
}