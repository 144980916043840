@charset "UTF-8";

@import "../../assets/scss/components/tokens/breadcrumbs.style";

$component: componentClass("breadcrumbs");
$cmp-tokens: ();

@if (variable-exists(zaux-breadcrumbs-style-tokens)){
    $cmp-tokens : $zaux-breadcrumbs-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--light1 .#{$component}{
    color:getCmpToken($cmp-tokens, 'color-cmp', tokenColor('set1','black1'), 'theme-light1' );
    a{
        color:getCmpToken($cmp-tokens, 'color-cmp', tokenColor('set1','black1'), 'theme-light1' );
    }
}