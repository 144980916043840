@charset "UTF-8";

@import "../../assets/scss/components/tokens/btn.style";

$component: componentClass("btn");
$cmp-tokens: ();

@if ($zaux-btn-style-tokens){
    $cmp-tokens : $zaux-btn-style-tokens;
}

$c-btn-hover-color-shift-factor: 10%;

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

@mixin button-primary-light1{
    transition:background-color $zaux-transition-1;
    border-radius:tokenRadius("pill");
    background-color:getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor('set1','color2'), 'theme-light1');
    color: getCmpToken($cmp-tokens, 'color-cmp-txtcolor', tokenColor('set1', 'white1'), 'theme-light1');
    &:hover{
        background-color:lighten(getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor('set1','color2'), 'theme-light1'), $c-btn-hover-color-shift-factor);
    }
    &:active, &.active{
        background-color:darken(getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor('set1','color2'), 'theme-light1'), $c-btn-hover-color-shift-factor);
    }
    &:is(input[type=submit]){
        color: getCmpToken($cmp-tokens, 'color-cmp-txtcolor', tokenColor('set1', 'white1'), 'theme-light1');
    }
}

@mixin button-secondary-light1{
    transition:background-color $zaux-transition-1;
    border-radius:tokenRadius("pill");
    background-color:getCmpToken($cmp-tokens, 'color-cmp-secondary-bg', tokenColor('set1','white1'), 'theme-light1');
    color: getCmpToken($cmp-tokens, 'color-cmp-secondary-txtcolor', tokenColor('set1', 'black1'), 'theme-light1');
    &:hover{
        background-color:lighten(getCmpToken($cmp-tokens, 'color-cmp-secondary-bg', tokenColor('set1','white1'), 'theme-light1'), $c-btn-hover-color-shift-factor);
    }
    &:active, &.active{
        background-color:darken(getCmpToken($cmp-tokens, 'color-cmp-secondary-bg', tokenColor('set1','white1'), 'theme-light1'), $c-btn-hover-color-shift-factor);
    }
}

.zaux-theme--light1 .#{$component} {
    font-family: $zaux-font1;
    &--primary{
        @include button-primary-light1;
    }
    &--secondary{
        @include button-secondary-light1;
    }
    &--theme3{
        transition:background-color $zaux-transition-1;
        border-radius:tokenRadius("pill");
        background-color:transparent;
        border-color:tokenColor("set1","color3");
        color: tokenColor("set1","color3");
        border-style:solid;
        &:hover{
            background-color:tokenColor("set1","white1");
            border-color:tokenColor("set1","white1");
        }
        &:active, &.active{
            background-color:darken(getCmpToken($cmp-tokens, 'color-cmp-secondary-bg', tokenColor('set1','white1'), 'theme-light1'), $c-btn-hover-color-shift-factor);
            color:tokenColor("set1","white1");
            border-color:tokenColor("set1","white1");
        }
    }
    &[disabled]{
        opacity:getCmpToken($cmp-tokens, 'opacity-btndisabled', 0.5, 'theme-light1');
    }
}