@charset "UTF-8";

@import "../../assets/scss/components/tokens/infocard.style";

$component: componentClass("infocard");
$cmp-tokens: ();

@if (variable-exists(zaux-infocard-style-tokens)){
    $cmp-tokens : $zaux-infocard-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color: getCmpToken($cmp-tokens, 'color-cmp-bg', tokenColor('set1','color6'), 'theme-light1');   
    color: getCmpToken($cmp-tokens, 'color-cmp-text', tokenColor('set1', 'color3') , 'theme-light1');
    border-radius:tokenRadius(2);
}