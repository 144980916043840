@charset "UTF-8";

@import "../../assets/scss/components/base";
@import "../../assets/scss/components/tokens/header.style";

$component: componentClass("header");
$cmp-tokens: ();

@if (variable-exists(zaux-header-style-tokens)){
    $cmp-tokens : $zaux-header-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color:tokenColor("set1","color2");
    color:tokenColor("set1","black1");
    a{
        text-decoration:none;
        appearance: none;
    }
    &__badge{
        background-color:getCmpToken($cmp-tokens, 'color-badge-bg', tokenColor("set1", "color1"), 'theme-light1');
        color:getCmpToken($cmp-tokens, 'color-badge-text', tokenColor("set1", "white1"), 'theme-light1');
    }
    &__social{
        li{
            border-color:tokenColor("set1","color5");
            border-style:solid;
        }
    }
    &__megamenu{
        background-color:rgba(tokenColor("set1", "color2"), 0.95);
        box-shadow:tokenShadow("sm") rgba(0,0,0,0.2);
        text-transform:uppercase;
        &[class*=--fade]{
            transition:all $zaux-transition-1;
            opacity:0;
            &[class*=--show]{
                opacity:1;
            }
        }
    }
    //box-shadow: getCmpToken($cmp-tokens, "dropshadow-header", tokenShadow('sm') rgba(tokenColor('set1', 'black1'), 0.4), "theme-light1");
    &__row{
        &--1{
            border-bottom:1px solid tokenColor("set1","color4");
        }
    }
    &__menu{
        &-item{
            list-style-type:none;
            color: tokenColor("set1", "color4");
            font-weight:$zaux-typo-fw-semibold;
            a{
                color:getCmpToken($cmp-tokens, "color-menu-items", tokenColor("set1", "color1"), "theme-light1");
                transition:color $zaux-transition-1;
            }
            &:hover{
                a{
                    color:getCmpToken($cmp-tokens, "color-menu-items-hover", tokenColor("set1", "color1"), "theme-light1");
                }
            }
            &[class*=--active]{
                [data-main-link]{
                    color:tokenColor("set1","color1");
                }
            }
        }
    }
    &.res{
        &--1{
            .#{$component}{
            }
        }
    }
}