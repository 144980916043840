@charset "UTF-8";

$component: componentClass("displayblock3");

.#{$component} {
    min-height:620px;
    &__inner{
        min-height:620px;
    }
    &__bg{
        overflow:hidden;
        height:100%;
        position:relative;
        img{
            position:absolute;
            left:0;
            right:0;
            top:0;
            bottom:0;
            aspect-ratio:1/1;
            height:100%;
            object-fit:cover;
            width:100%;
        }
    }

    @include media-breakpoint-down("sm"){
        min-height:auto;
        &__inner{
            min-height:auto;
        }
    }

}