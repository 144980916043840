@charset "UTF-8";

@import "../../assets/scss/components/tokens/cardproduct1.style";

$component: componentClass("cardproduct1");
$cmp-tokens: ();

@if (variable-exists(zaux-cardproduct1-style-tokens)){
    $cmp-tokens : $zaux-cardproduct1-style-tokens;
}

.#{$component} {
    overflow:hidden;
    min-height:getCmpToken($cmp-tokens, 'css-inner-minh', 440px, 'layout');
    cursor:pointer;
    &__inner{
        padding-left:getCmpToken($cmp-tokens, 'spacer-inner-pl', tokenSpacer(5), 'layout');
        padding-right:getCmpToken($cmp-tokens, 'spacer-inner-pr', tokenSpacer(8), 'layout');
        padding-top: getCmpToken($cmp-tokens, 'spacer-inner-pt', tokenSpacer(5), 'layout');
        padding-bottom: getCmpToken($cmp-tokens, 'spacer-inner-pb', tokenSpacer(5), 'layout');
        text-align:getCmpToken($cmp-tokens, 'css-inner-txtalign', left, 'layout');
        height:inherit;
        display:flex;
        justify-content:space-between;
        flex-direction:column;
        position:relative;
        min-height:inherit;

        .#{$component}__img{
            position:absolute;
            bottom:tokenSpacer(4);
            left:50%;
            transform:translateX(-50%);
            object-fit: contain;
        }

        .#{$component}__head{
            z-index: tokenZindex("content");
            .#{$component}__pre-title{
                text-transform:uppercase;
                font-size:getCmpToken($cmp-tokens, 'fs-pretitle', tokenTypoSizeFs(9), 'layout');
                //letter-spacing:getCmpToken($cmp-tokens, 'ls-pretitle', tokenLetterSpacing(1), 'layout');
                margin-bottom:getCmpToken($cmp-tokens, 'spacer-pretitle-mb', tokenSpacer(4), 'layout' );
            }
            .#{$component}__title{
                font-size:getCmpToken($cmp-tokens, 'fs-title', tokenTypoSizeFs(15), 'layout');
                font-weight:getCmpToken($cmp-tokens, 'fw-title', $zaux-typo-fw-medium, 'layout');
                margin-bottom:getCmpToken($cmp-tokens, 'spacer-title-mb', tokenSpacer(3), 'layout');
            }
            .#{$component}__abstract{
                font-size:getCmpToken($cmp-tokens, 'fs-subtitle', tokenTypoSizeFs(9b), 'layout');
                line-height:getCmpToken($cmp-tokens, 'lh-subtitle', tokenTypoSizeLh(9b), 'layout');
                margin-bottom:getCmpToken($cmp-tokens, 'spacer-subtitle-mb', tokenSpacer(1), 'layout');
                font-weight:getCmpToken($cmp-tokens, 'fw-abstract', $zaux-typo-fw-medium, 'layout');
            }
            .#{$component}__marked-txt{
                font-weight:getCmpToken($cmp-tokens, 'fw-markedtxt', $zaux-typo-fw-bold, 'layout');
                margin-bottom:getCmpToken($cmp-tokens, 'spacer-markedtxt-mb', tokenSpacer(4), 'layout');
            }
        }
    }

    &__foot{
        position:relative;
        z-index:99;
    }

}
