@charset "UTF-8";

$component: componentClass("photocard");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    color:tokenColor("set1","white1");
}