@charset "UTF-8";

$component: componentClass("displayblock3");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{

    background-color:tokenColor("set1","color7");
    color:tokenColor("set1","color3");

    &__title{
    }

    &__subtitle{
    }

    &--theme2{
        background-color:tokenColor("set1","color2");
        color:tokenColor("set1","color3");
    }

}