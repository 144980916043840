@charset "UTF-8";

@import "../../assets/scss/components/tokens/cardicon2.style";

$component: componentClass("cardicon2");
$cmp-tokens: ();

@if ($zaux-cardicon2-style-tokens){
    $cmp-tokens : $zaux-cardicon2-style-tokens;
}

.#{$component} {

    &__inner{
        padding: getCmpToken($cmp-tokens, 'spacer-inner-pxy', tokenSpacer(6), 'layout');
        text-align:getCmpToken($cmp-tokens, 'css-inner-txtalign', center, 'layout');
        .#{$component}__head{
            .#{$component}__icon{
                margin-bottom:getCmpToken($cmp-tokens, 'spacer-head-icon-mb', tokenSpacer(6), 'layout');
            }
        }
        .#{$component}__body{
            margin-bottom:getCmpToken($cmp-tokens, 'spacer-body-mb', tokenSpacer(3), 'layout');
            .#{$component}__title{
                font-weight: getCmpToken($cmp-tokens, 'fw-bodytitle', $zaux-typo-fw-semibold, 'layout');
                text-transform:uppercase;
                @include tokenTypoStyle(getCmpToken($cmp-tokens, 'typo-bodytitle', 9,'layout'));
            }
        }
        .#{$component}__foot{
            .#{$component}{
                &__subtitle{
                    font-family: $zaux-font2;
                    font-size: getCmpToken($cmp-tokens, 'fs-subtitle', tokenTypoSizeFs(6), 'layout');
                    line-height: getCmpToken($cmp-tokens, 'lh-subtitle', tokenTypoSizeLh(3), 'layout');
                }
            }
        }
        .#{$component}{
            &__row{
                &--1{
                    padding-bottom:getCmpToken($cmp-tokens, 'spacer-row1-pb', tokenSpacer(8), 'layout');
                }
            }
        }
    }
    
}