@charset "UTF-8";

@import "../../assets/scss/components/base";
@import "../../assets/scss/components/tokens/heroslider2.style";

$component: componentClass("heroslider2");
$cmp-tokens: ();

@if ($zaux-heroslider2-style-tokens){
    $cmp-tokens : $zaux-heroslider2-style-tokens;
}

.#{$component} {

    .#{$component-prefix}slider__instance{
        padding-bottom:getCmpToken($cmp-tokens, 'spacer-slider-pb', tokenSpacer(8), 'layout');
    }
    
    &__slide{
        min-height: getCmpToken($cmp-tokens, 'css-slide-minheight', 750px, 'layout');
        &-content{
            height:inherit;
            display:flex;
            justify-content:center;
            flex-direction:column;
            overflow:hidden;
            position:relative;
            padding: getCmpToken($cmp-tokens, 'spacer-cmp-py', tokenSpacer(6), 'layout') 0;
        }
        &-visual{
            position:absolute;
            bottom:0;
            right:-100px;
            height:100%;
            width:auto;
            top:0;
            img{
                bottom:0;
                right:0;
                width:auto;
                height:100%;
            }
        }
    }

    &__pre-title{
        display:inline-block;
        padding: tokenSpacer(1) tokenSpacer(4);
        @include tokenTypoStyle(6);
        letter-spacing:tokenLetterSpacing(1);
        text-transform:uppercase;
        margin-bottom:getCmpToken($cmp-tokens, 'spacer-pretitle-mb', tokenSpacer(6), 'layout');
        font-weight:getCmpToken($cmp-tokens, 'fw-pretitle', $zaux-typo-fw-bold, 'layout');
    }

    &__title{
        //@include tokenTypoStyle(getCmpToken($cmp-tokens, 'typostyle-title', 17, 'layout'));
        font-weight: getCmpToken($cmp-tokens, 'fw-title', $zaux-typo-fw-semibold, 'layout');
    }

    &__subtitle{
        padding: getCmpToken($cmp-tokens, 'spacer-subtitle-py', tokenSpacer(6), 'layout') 0;
        @include tokenTypoStyle(getCmpToken($cmp-tokens, 'typostyle-subtitle', 13, 'layout'));
        font-family: $zaux-font2;
    }
    &__cta{
        //margin-bottom:getCmpToken($cmp-tokens, 'spacer-cta-mb', tokenSpacer(8b), 'layout');
    }
    &__badge{
        height:getCmpToken($cmp-tokens, 'css-badge-height', 82px, 'layout' );
        width:getCmpToken($cmp-tokens, 'css-badge-width', 82px, 'layout' );
        padding-left:getCmpToken($cmp-tokens, 'spacer-badge-pl', tokenSpacer(2), 'layout');
        padding-right:getCmpToken($cmp-tokens, 'spacer-badge-pr', tokenSpacer(2), 'layout');
        text-align:center;
        display:flex;
        justify-content:center;
        align-items:center;
        font-size: getCmpToken($cmp-tokens, 'fs-badge', tokenTypoSizeFs(14), 'layout');
    }
    &__badge-list{
        margin-left:-#{tokenSpacer(4)};
        .#{$component}__badge-list-item{
            display:inline-block;
            margin-left:tokenSpacer(4);
            vertical-align:top;
        }
    }

    &.res{
        &--1{
            .#{$component}__title{
                @include media-breakpoint-down("md"){
                    //@include tokenTypoStyle(getCmpToken($cmp-tokens, 'typostyle-title', 16, 'layout'));
                }
            }
            .#{$component}__subtitle{
                @include media-breakpoint-down("md"){
                    //@include tokenTypoStyle(getCmpToken($cmp-tokens, 'typostyle-subtitle', 10, 'layout'));
                }
            }
            .#{$component}__slide{
                @include media-breakpoint-down("md"){
                    min-height:auto;
                }
                &-visual{
                    @media (min-width: #{tokenBreakpoint("xl") + 1} ){
                        right:0;
                    }
                    @include media-breakpoint-down("lg"){
                        opacity:0.5;
                    }
                    @include media-breakpoint-down("md"){
                        opacity:0.3;
                        right:-200px;
                    }
                }
            }
        }
    }

}