@charset "UTF-8";

@import "../../assets/scss/components/base";
@import "../../assets/scss/components/tokens/heroslider.style";

$component: componentClass("heroslider");
$cmp-tokens: ();

@if ($zaux-heroslider-style-tokens){
    $cmp-tokens : $zaux-heroslider-style-tokens;
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color: getCmpToken($cmp-tokens, 'color-component-bg', tokenColor("set1", "color3"), 'theme-light1' );
    &__slide{
        background-color: getCmpToken($cmp-tokens, 'color-slide-bg', tokenColor("set1", "color5"), 'theme-light1');
        &-pre-title{
            text-transform:uppercase;
        }
        &-subtitle{
            text-transform:uppercase;
            color: getCmpToken($cmp-tokens, 'color-subtitle', tokenColor("set1", "black1"), 'theme-light1');
        }
    }
    &__badge{
        background-color:getCmpToken($cmp-tokens, 'color-badge-bg', tokenColor("set1", "color2"), 'theme-light1');
        border-radius:tokenRadius("pill");
    }
}